import {Navigate} from "react-router-dom"
import {UrlsEnum} from "../enums/urls.enum";
import {useContext} from "react";
import {AuthContext} from "../lib/AuthContext";

interface Props {
  children: React.ReactElement
}

const ProtectedRoute: React.FC<Props> = ({children}) => {

  const authContext = useContext(AuthContext)
  if (!authContext.authenticated) {
    return <Navigate to={UrlsEnum.LOGIN} replace={true} />
  }
  return children
}

export default ProtectedRoute;