import React, {useEffect, useRef, useState} from "react";
import {HorizontalBox, SpaceBox} from "../Containers";
import {ButtonRounded, ErrorIndicator, FormField, FormProps, InputText, TextLabel} from "./Common";
import Popper from '@mui/material/Popper';
import {Box} from "@mui/material";
import styled from "@emotion/styled";
import useWindowSize from "../../hooks/useWindowSize";
import Backdrop from '@mui/material/Backdrop';


interface Props extends Omit<FormProps, "initialValue"> {
  link: string
}


const FileField: React.FC<Props> = (props) => {

  const [anchorPreview, setAnchorPreview] = React.useState<null | HTMLElement>(null);
  const [previewData, setPreviewData] = useState<string | null>( null)
  const {name = "", label,
    required, error,
    register, setValue,
    width = "320px", padding = "0",
    link
  } = props;

  const windowSize = useWindowSize()


  const refLink = useRef<HTMLInputElement>(null);
  const refFile = useRef<HTMLInputElement>(null);

  const onFileClick = () => {
    refFile.current?.click();
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const file = fileList && fileList[0];
    if (file != null) {
      if (file.type.startsWith("image/")) {
        setPreviewData(URL.createObjectURL(file));
      }
    } else {
      setPreviewData(null )
    }
    setValue(name, file, { shouldValidate: true })
    setLink()
  }

  const setLink = () => {
    const fileList = refFile.current?.files
    const file = fileList && fileList[0];
    if (file != null) {
      refLink.current!.value = file.name
    }
  }

  const togglePreview = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPreview(anchorPreview ? null : event.currentTarget);
  };


  useEffect(() => {
    setLink()
  });

  const mandatory = required && link === ""

  return (


    <FormField width={width} sx={{padding: padding}}>



      <TextLabel position={"start"}>{label}</TextLabel>
      <input type="file"
             style={{"display":"none"}}
             {...register(name, {required: mandatory}) }
             accept={"image/*"}
             ref={refFile}
             onChange={onFileChanged}/>

      <HorizontalBox>
        {previewData &&
          <>
            <Box width={50} height={40}>
              <PreviewMini src={previewData} onClick={togglePreview}/>
            </Box>
            <Backdrop
              sx={{ color: '#fff', zIndex:2}}
              open={anchorPreview !== null}
              onClick={ () => setAnchorPreview(null)}>
            <Popper id={"popper"+name}
                    sx={{ color: '#fff', zIndex:3}}

                    open={anchorPreview !== null}
                    anchorEl={anchorPreview}
                    placement={"top"}
                    modifiers={[
                      {
                        name: 'flip',
                        enabled: false,
                        options: {
                          altBoundary: false,
                          rootBoundary: 'viewport',
                          padding: 8,
                        },
                      },
                      {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: true,
                          rootBoundary: 'document',
                          padding: 8,
                        },
                      }
                    ]}>
              <Box sx={{ maxWidth: windowSize.width * 0.8 +"px", height: windowSize.width * 0.5 +"px" }}>
                <PreviewFull src={previewData}/>
              </Box>
            </Popper>
            </Backdrop>

          </>}
        <InputText variant={"outlined"}
                   type="text"
                   inputRef={refLink}
                   value={link}
                   inputProps={{readOnly: true}}
        />
        <SpaceBox size={10}/>
        <ButtonRounded onClick={onFileClick} variant="contained" color="primary" size="small">Carica</ButtonRounded>
        <ErrorIndicator error={error}/>

      </HorizontalBox>

    </FormField>

  )
}

export default FileField

const PreviewMini = styled.img`
  display: flex;
  height: 100%;
  width: 90%;
  object-fit: scale-down;
`;

const PreviewFull = styled.img`
  display: flex;
  margin: 1px;
  width: 100%;
  max-height: calc(100% - 10px);
  z-index: 5;
  object-fit: scale-down;
`;
