import {cleanupData} from "../utils/Functions";

export interface ProfileModel extends ProfileDataPersonalResidence, ProfileDataBirthDocument {
  id?: string
  operatorKey?: string
  creationDate?: string
  userComplete?: boolean
  profileVerified?: boolean

  legalName?: string
}


export interface ProfileDataPersonalResidence extends ProfileDataPersonal, ProfileDataResidence {}

export interface ProfileDataBirthDocument extends ProfileDataBirth, ProfileDataDocument {}

export interface ProfileDataPersonal {
  userId?: string
  cf: string
  firstName: string
  middleNames?: string
  lastName: string
  sex: string,
  phone: string
  phoneCountryCode: string,

  acceptedTerms: boolean
  acceptedSpecialTerms: boolean
  acceptedPrivacy: boolean
}

export interface ProfileDataResidence {
  residenceCity: string
  residenceAddress: string
  residenceCountry?: string
  residenceCAP?: string
  residenceProvince?: string
}

export interface ProfileDataBirth {
  birthDate: string
  birthCity: string
  birthCountry: string
  birthProvince?: string
}
export interface ProfileDataDocument {
  docType: string
  docNum: string
  docExpiryDate: string
  docPictureFrontLink: string | null
  docPictureBackLink: string | null
  docPictureFrontFile: File | null
  docPictureBackFile: File | null
}

export const createProfileModel = () : ProfileModel =>  {
  return  {
    userId: "",

    cf: "",
    firstName: "",
    middleNames: "",
    lastName: "",
    sex: "",
    phone: "",
    phoneCountryCode: "",

    residenceCity: "",
    residenceProvince: "",
    residenceAddress: "",
    residenceCountry: "",
    residenceCAP: "",

    birthDate: "",
    birthCity: "",
    birthCountry: "",
    birthProvince: "",

    docType: "",
    docNum: "",
    docExpiryDate: "",
    docPictureFrontFile: null,
    docPictureBackFile: null,
    docPictureFrontLink: "",
    docPictureBackLink: "",

    acceptedTerms: false,
    acceptedSpecialTerms: false,
    acceptedPrivacy: false
  }
}

export const getProfilePersonal = (data: ProfileModel): ProfileDataPersonal => {
  return {
    userId: data.userId,
    cf: data.cf,
    firstName: data.firstName,
    middleNames: data.middleNames,
    lastName: data.lastName,
    sex: data.sex,
    phone: data.phone,
    phoneCountryCode: data.phoneCountryCode,

    acceptedTerms: data.acceptedTerms,
    acceptedSpecialTerms: data.acceptedSpecialTerms,
    acceptedPrivacy: data.acceptedPrivacy
  }
}


export const getProfileResidence = (data: ProfileModel) : ProfileDataResidence => {
  return {
    residenceCity: data.residenceCity,
    residenceProvince: data.residenceProvince,
    residenceAddress: data.residenceAddress,
    residenceCountry: data.residenceCountry,
    residenceCAP: data.residenceCAP
  }
}

export const getProfilePersonalResidence = (data: ProfileModel): ProfileDataPersonalResidence => {
  return {...getProfilePersonal(data), ...getProfileResidence(data)}
}

export const getProfileBirth = (data: ProfileModel) : ProfileDataBirth => {
  return {
    birthDate: data.birthDate,
    birthCity: data.birthCity,
    birthCountry: data.birthCountry,
    birthProvince: data.birthProvince
  }
}

export const getProfileDocument = (data: ProfileModel) : ProfileDataDocument => {
  return {
    docType: data.docType,
    docNum: data.docNum,
    docExpiryDate: data.docExpiryDate,
    docPictureFrontLink: data.docPictureFrontLink,
    docPictureBackLink: data.docPictureBackLink,
    docPictureFrontFile: data.docPictureFrontFile,
    docPictureBackFile: data.docPictureBackFile,
  };
}

export const getProfileDataBirthDocument = (data: ProfileModel): ProfileDataBirthDocument => {
  return {...getProfileBirth(data), ...getProfileDocument(data)}
}


export const getRecipient = (profile?: ProfileModel) => {
  return  profile?.legalName ? profile.legalName
    : profile?.lastName ? profile?.firstName +" "+profile?.lastName
      : "NA"
}


export const DocSide = {
  front: "front",
  back: "back"
}

const isEmpty = (prop: any) => {
  return prop === null || prop === undefined || prop === ""
}
export const cleanProfile = (profile: ProfileModel) => {
  return Object.fromEntries(Object.entries(profile).filter(([_, prop]) => !isEmpty(prop))) as ProfileModel
}

const fieldsPortrait = [
  ["cf", "firstName", "middleNames", "lastName", "sex", "phone", "phoneCountryCode", "acceptedTerms"],
  ["residenceCity", "residenceAddress", "residenceCountry", "residenceCAP", "residenceProvince"],
  ["birthDate", "birthCity", "birthCountry", "birthProvince"],
  ["docType", "docNum", "docExpiryDate", "docPictureFrontFile", "docPictureFrontLink", "docPictureBackFile", "docPictureBackLink"]
]

const fieldsLandscape = [
  ["cf", "firstName", "middleNames", "lastName", "sex", "phone", "phoneCountryCode", "residenceCity", "residenceAddress", "residenceCountry", "residenceCAP", "residenceProvince", "acceptedTerms"],
  ["birthDate", "birthCity", "birthCountry", "birthProvince", "docType", "docNum", "docExpiryDate", "docPictureFrontFile", "docPictureFrontLink", "docPictureBackFile", "docPictureBackLink"]
]


export const filterFormData  = (step: number, lastStep: number, portrait: boolean,  updated: any, source: any) => {
  const fields = portrait ? fieldsPortrait : fieldsLandscape
  let changed = !fields[step].every( (field => (updated[field] === source[field]) || (isEmpty(updated[field]) && isEmpty(source[field]))) )
  if (step === lastStep && (updated["docPictureFrontFile"] !== null || updated["docPictureBackFile"] !== null)) {
    changed = true
  }
  const filtered = cleanupData(updated, fields[step]) as unknown as ProfileModel
  return {filtered, changed}
}


export const getDocumentOptions = (t:any) => {
  return [
    { value: "ci", label: t("profile.doctype.ci") },
    { value: "passport", label: t("profile.doctype.passport") },
    { value: "licence", label: t("profile.doctype.license") }
  ]
}

export const getSexOptions = (t:any) => {
  return [
    { value: "M", label: t("profile.sex.male") },
    { value: "F", label: t("profile.sex.female") }
  ]
}


/***
 * @param {object} data the data to update the profile with.
 * Fields have to follow this strict convention:
 *  - cf                : codice fiscale
 *  - firstName         : first name
 *  - lastName          : last name
 *  - residenceCity     : city of residence
 *  - residenceAddress  : address of residence
 *  - birthDate         : date of birth, as a moment() object
 *  - birthCity         : city of birth
 *  - birthCountry      : country of birth
 *  - docType           : document type
 *  - docNum            : document number
 *  - docExpiryDate     : date of expiry of the doc, as a moment() object
 *  - docPhotoFrontLink : link of the photo front
 *  - docPhotoBackLink  : link of the back of the photo
 */



/*
Motorgest può creare nuovi profili per conto dei suoi clienti.

Per creare un nuovo profilo Pleggit per un suo cliente, Motorgest deve:
Registrare l’utente su Pleggit.
Creare un Profilo Pleggit per conto dell’utente

----------------------------------------------------------------------------------------
1. Registrazione di un nuovo utente su Pleggit

Per registrare un utente su Pleggit, Motorgest deve solo inserire:
Mail dell’utente

Pleggit genera una password per l’utente e attiva direttamente l’utenza, senza che sia necessario l’invio di una mail di registrazione.

----------------------------------------------------------------------------------------
2. Creazione Profilo per conto dell’utente

Per creare un profilo Pleggit per l’utente, Motorgest deve inserire tutti i campi necessari.
Questi sono (tutti i campi inquadrati da [..] sono opzionali):
Codice Fiscale
Nome
[Secondi Nomi]
Cognome
Sesso
Indirizzo di Residenza, suddiviso in:
Paese (stato)
Città
Indirizzo
Codice Postale
Numero di cellulare (prefisso + numero)
Dati di nascita, suddivisi in:
Data
Paese di nascita (stato)
Provincia
Città
Documento di identità, con i seguenti campi:
Tipo documento (carta di identità, passaporto, patente)
Numero Doc
Scadenza
Foto fronte (o esterno)
Foto retro (o interno)
Conferma di Accettazione di privacy e T&C da parte dell’utente (vedi app mobile per gli URL)

Al momento non è prevista la possibilità di creare profili per Aziende.

 */



/*
api.path('GET', '/operators/:operatorKey/profiles', getOperatorProfiles);
api.path('GET', '/operators/:operatorKey/profiles/:id', getOperatorProfileById);
api.path('PUT', '/profile', profileManager.createOrUpdateProfile);

        const operatorKey = req.body.operatorKey;


 */


/*
acceptedPrivacy: true
acceptedPrivacyOn: "20231016"
acceptedSpecialTerms: true
acceptedSpecialTermsOn: "20231016"
acceptedTerms: true
acceptedTermsOn: "20231016"
birthCity: "Spinazzola"
birthCountry: "it"
birthDate: "20000903"
birthProvince: "BT"
cf: "GGNSHE36356FJFHH"
cfPass: false
creationDate: 20231016
docExpiryDate: "20360825"
docNum: "GWCDCC"
docPictureBackLink: "back"
docPictureFrontLink: "front"
docType: "cartaid"
email: "conc.test2@pleggit.com"
firstName: "Giovanni"
id: "652d21495c851c7262efec4e"
lastName: "Picture"
ocrConfidence: 0
ocrMatches: [] (0)
ocrPass: false
phone: "99556362"
phoneCountryCode: "+45"
phoneVerifiedOn: "20231016 13.44.16"
profileRequiresUserFix: null
profileVerified: true
profileVerifiedBy: "nicolas.matteazzi@gmail.com"
profileVerifiedDate: "20231016"
residenceAddress: "Via Cio 24"
residenceCAP: "20154"
residenceCity: "Mila"
residenceCountry: "it"
sex: "M"
userComplete: true
profileVerified: true

userFixMessage: null
userId: "ebbf4d1e-e1a4-4e6a-b902-9d2c463aa02b"
userType: "private"

 */

