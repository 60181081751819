import {useQuery} from "@tanstack/react-query";
import ProfileService from "../services/ProfileService";
import {ProfileModel} from "../models/profile.models";
import {QueryMode} from "../models/constants.enum";


const listOperatorProfiles = async (): Promise<ProfileModel[]> => {
  const { data } = await ProfileService.listOperatorProfiles()
  return data.profiles
};

const listProfiles = async (): Promise<ProfileModel[]> => {
  const { data } = await ProfileService.listProfiles()
  return data.profiles
};

const useListProfiles = (mode: QueryMode) => {

  return useQuery<ProfileModel[], string>({
    queryKey: ["profiles", mode],
    queryFn: () => {
      switch (mode) {
        case QueryMode.USERID:
          return listProfiles()
        case QueryMode.OPERATOR:
          return listOperatorProfiles()
        default:
          return Promise.resolve([] as ProfileModel[])
      }
    }
  });
}
export default useListProfiles;

