import React from 'react'
import {TextMedium, TextSmall} from "../Texts"
import {Box} from "@mui/material"
import CopyClip from "../CopyClip";

export interface LabelValueProp {
  label: string
  value: string
  copy?: boolean
}

const LabelValue: React.FunctionComponent<LabelValueProp> = ({label, value, copy= false}) => {

  return (
    <Box mt={1} height={"48px"}>
      <TextSmall $color={"#C0C0C0"}>{label}</TextSmall>
      <CopyClip content={value} visible={copy}>
        <TextMedium $color={"#001964"}>{value}</TextMedium>
      </CopyClip>
    </Box>
  )
}

export default LabelValue