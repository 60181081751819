import http from "./http"

const BASE_URL = process.env.REACT_APP_COMPANY_API_ENDPOINT


const getInfo = () =>  {
  return http.get("/info", {baseURL: BASE_URL})
}


const CompanyService = {
  getInfo
}

export default CompanyService