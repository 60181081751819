import React, {FunctionComponent, useEffect, useState} from "react";
import Spinner from "../lib/components/Spinner";
import {ProfileModel} from "../lib/models/profile.models";
import useGetProfile from "../lib/hooks/useGetProfile";
import useSaveProfile from "../lib/hooks/useSaveProfile";
import {EditMode, QueryMode} from "../lib/models/constants.enum";
import {useParams} from "react-router-dom";
import {CenterFull, Warning} from "../lib/components/Containers";
import FormProfileWrapper from "../components/FormProfileWrapper";

type FormProfileHandle = React.ElementRef<typeof FormProfileWrapper>;

const ProfileEdit: FunctionComponent = () => {

  const formRef = React.useRef<FormProfileHandle>(null)
  const onSaved = (updates: ProfileModel) => {
    setState( { ...state, ...updates})
    formRef.current?.advance()
  }

  const {profileId } = useParams();
  const { data: profile, isPending: isProfileLoading, isSuccess: isLoaded, isError: isLoadFailed, error: loadErrors } = useGetProfile(QueryMode.OPERATOR, profileId)
  const { mutate: save, isPending: isSaving, error: saveErrors  } = useSaveProfile(onSaved)
  const [ state, setState] = useState<ProfileModel>()

  const updateProfile = (formData: ProfileModel) => {
    save(formData);
  }

  useEffect(() => {
    if (profile) {
      setState( prevState => { return {...prevState, ...profile} })
//      setState({...state, ...profile})
    }
  }, [profile]);

  return (
  <>
    {isLoaded && state &&
      <FormProfileWrapper profile={state}
                          ref={formRef}
                          mode={EditMode.UPDATE}
                          save={updateProfile}
                          saving={isSaving}
                          errors={saveErrors} />}


    {isProfileLoading &&
      <CenterFull>
        <Spinner />
      </CenterFull>}

    {isLoadFailed &&
      <Warning severity="warning">{loadErrors}</Warning>}
  </>

  )
}

export default ProfileEdit
