import React from "react";
import {TextField} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";
import {HorizontalBox} from "../Containers";
import styled from "@emotion/styled";
import {ErrorIndicator, FormField, FormProps, TextLabel} from "./Common";
import {BaseTimeValidationProps} from "@mui/x-date-pickers/internals/models/validation";


interface Props extends FormProps, BaseTimeValidationProps {}

const CalendarField: React.FC<Props> = (props) => {

  const {name = "", label, defaultValue = "",
    required, error, placeholder,
    disableFuture, disablePast,
    width = "320px", padding = "0",
    register, setValue
  } = props;


  let initialTime = undefined
  if (defaultValue !== "") {
    initialTime = moment(defaultValue as string, "YYYYMMDD")
  }

  const handleChange = (newValue: moment.Moment) => {
    const value = newValue?.format("YYYYMMDD")
    setValue(name, value, { shouldValidate: true, shouldDirty: value !== defaultValue })
  }


  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormField width={width} sx={{padding: padding}}>
        <TextField sx={{display: "none"}}
                   {...register(name, {required: required}) }/>

        <TextLabel position={"start"} >{label}</TextLabel>
        <HorizontalBox>
          <BorderedDatePicker defaultValue={initialTime}
                              disablePast={disablePast}
                              disableFuture={disableFuture}
                              label={placeholder}
                              onChange={(value:any) => handleChange(value)}/>
          <ErrorIndicator error={error}/>

        </HorizontalBox>
      </FormField>
    </LocalizationProvider>
  )
}

export default CalendarField


const BorderedDatePicker = styled(DatePicker)`
  border-radius: 6px;
  border: 1px solid  rgba(0, 0, 0, 0.23);
  width: 100%;
  
  > label {
    color: darkgray;
    transform: translate(14px, 11px) scale(1);
  }
  
  > label[data-shrink="true"] {
    display: none;
  }
  
  > div > input {
    padding: 15px 12px;
  }
  > div > fieldset {
    border: none;
  }

  :hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`