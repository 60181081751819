import React from 'react'

import {SpaceBox, ColumnCenter, Warning} from "../Containers"
import {useTranslation} from "react-i18next"
import {TransactionModel} from "../../models/transactionModel"
import BoxTrans from "./BoxTrans"
import {LoadingButtonRounded} from "../fields/Common"
import {TextLarge} from "../Texts";
import IconCheck from "@mui/icons-material/Check";


export interface PinProps {
  onPinSubmit: (pin: string) => void
  pinPosting: boolean
  pinSuccess: boolean
  pinError?: any
}
interface Props extends PinProps {
  header: string
  title: string
  transaction: TransactionModel
}



const BoxTransConfirm: React.FunctionComponent<Props> = ({header, title, onPinSubmit, pinPosting, pinError, pinSuccess, transaction}) => {


  const { t } = useTranslation()


  const exchangeCodeUsed = transaction.counterpartInsertedEcode || false
  const counterpartCode = transaction?.counterpartECode || ""
  const counterpartCodeUsed = counterpartCode !== "" || pinSuccess
  const disabled = exchangeCodeUsed && counterpartCodeUsed


  const submitPins = () => {
    onPinSubmit("")
  }



  return (
        <BoxTrans header={header}
                  title={title}
                  background={"#F4F4F4"}>
          <ColumnCenter>
            <ColumnCenter>

              <SpaceBox size={16}/>
              <TextLarge>&nbsp;</TextLarge>
              <SpaceBox size={8}/>
            </ColumnCenter>

            <LoadingButtonRounded variant="contained"
                                  loading={pinPosting}
                                  onClick={submitPins}
                                  width={"160px"}
                                  startIcon={disabled && <IconCheck/>}
                                  disabled={disabled}>
              { disabled ? t("transaction.text.confirmed") : t("button.label.confirm")}
            </LoadingButtonRounded>

            <Warning style={{visibility: pinError ? "visible": "hidden"}} severity={"error"}>{t(""+pinError)}</Warning>

          </ColumnCenter>

        </BoxTrans>


  )
}

export default BoxTransConfirm

