import React, {useState} from "react";
import {TextField} from "@mui/material";
import {HorizontalBox} from "../Containers";
import {ErrorIndicator, FormField, FormProps, TextLabel} from "./Common";
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/material.css'

interface Props extends FormProps {
  nameCountryCode?: string
  defaultCountryCodeValue?: string
}

const PhoneField: React.FC<Props> = (props) => {

  const [touched, setTouched] = useState(false)
  const {name = "", label, defaultValue = "",
    required, error,
    register, setValue,
    width = "400px", padding = "0",
    nameCountryCode = "", defaultCountryCodeValue = ""
  } = props;


  const handleChange = (value: string, country: any, formattedValue: string) => {
    setTouched(true)
    let countryCodeValue = ""
    const countryValueIndex = value.indexOf(country.dialCode)

    if (countryValueIndex === 0) {
      countryCodeValue = country.dialCode
      value = value.substring(country.dialCode.length)
    }

    setValue(name, value, { shouldValidate: true, shouldDirty: value !== defaultValue })
    setValue(nameCountryCode, countryCodeValue, { shouldValidate: true, shouldDirty: countryCodeValue !== defaultCountryCodeValue })
  }

  return (
    <FormField width={width} sx={{padding: padding}}>

        <TextField sx={{display: "none"}}
                   {...register(name, {required: required}) }/>

        <TextField sx={{display: "none"}}
                   {...register(nameCountryCode) }/>

        <TextLabel position={"start"} >{label}</TextLabel>
        <HorizontalBox>
          <PhoneInput
            isValid={ (value) => (required && touched) ? (value.length > 0) : true}
            placeholder={props.placeholder}
            value={defaultCountryCodeValue + defaultValue}
            inputProps={{required: required, width: "100%"}}
            onChange={(value, country, e, formattedValue) => handleChange(value, country, formattedValue)}
          />
          <ErrorIndicator error={error}/>
        </HorizontalBox>
      </FormField>

  )
}

export default PhoneField


