import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {Page, ColumnCenter} from "../Containers";
import Uploader from "../Uploader";
import {FormContainer, H2Center} from "../fields/Common";


interface Props {
  file?: File | null
  onChanged: (file: File | null) => void
}

const FormTransUpload: FunctionComponent<Props> = ({file = null, onChanged}) => {

  const { t } = useTranslation();

  return (

    <Page>
      <ColumnCenter>

        <H2Center>{t("transaction.create.step3.text")}</H2Center>

        <br/>

        <FormContainer>
          <Uploader size={"large"} onChanged={onChanged} file={file}/>
        </FormContainer>

      </ColumnCenter>
    </Page>
  )

}

export default FormTransUpload