import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {UserLogin} from "../lib/models/user.models";
import InputTextField from "../lib/components/fields/InputTextField";
import {HorizontalBox, SpaceBox} from "../lib/components/Containers";
import IconNext from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import validator from "validator";
import {LoadingButtonRounded} from "../lib/components/fields/Common";

interface Props {
  onSubmit: (user: UserLogin) => void
  fieldWidth?: string,
  pending: boolean
}

const FormLogin: FunctionComponent<Props> = ({onSubmit, pending, fieldWidth = "400px"}) => {

  const { t } = useTranslation()
  const { register, formState: { errors, isValid }, getValues}
    = useForm<UserLogin>({ mode: "onChange" });

  const handleSubmit = () => {
    onSubmit(getValues())
  }

  return (
    <Container>

        <SpaceBox size={40}/>

        <InputTextField name={"username"}
                        label={t("login.label.username")}
                        error={!!errors["username"]}
                        width={fieldWidth}
                        required
                        register={register}
                        validator={validator.isEmail}
                        placeholder={t("login.placeholder.username")}/>

        <InputTextField name={"password"}
                        type={"password"}
                        label={t("login.label.password")}
                        width={fieldWidth}
                        required
                        error={!!errors["password"]}
                        register={register}
                        placeholder={t("login.placeholder.password")}/>

        <HorizontalBox mt={"10px"} mb={"10px"}>
          <LoadingButtonRounded
            loading={pending}
            onClick={handleSubmit}
            width={"160px"}
            variant="contained"
            endIcon={<IconNext />}
            disabled={!isValid}>
            {t("button.label.login")}
          </LoadingButtonRounded>
        </HorizontalBox>

    </Container>
  )
}

export default FormLogin


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;