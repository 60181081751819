import {LoginMode, QueryMode} from "./lib/models/constants.enum";


/*
export let QUERY_MODE = QueryMode.USERID
export let LOGIN_TYPE = "private"
export let MERGE_DOUBLE_TRANSACTIONS = false
*/


export let QUERY_MODE = QueryMode.OPERATOR
export let LOGIN_TYPE = LoginMode.COMPANY
export let MERGE_DUAL_TRANSACTIONS = true


