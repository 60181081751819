import React from "react";
import IconCopy from "@mui/icons-material/ContentCopy"
import styled from "@emotion/styled";
import Snackbar from '@mui/material/Snackbar'
import {useTranslation} from "react-i18next";
interface Props {
  content: string
  message?: string
  visible?: boolean
  size?: "small" | "large" | "medium"
  position?: "absolute" | "relative"
  children: React.ReactNode
}

const CopyClip: React.FunctionComponent<Props> =  ({
  children,
  content,
  visible = true,
  size = "small",
  position = "relative",
  message = "text.copy.clipboard"
}) => {

  const { t } = useTranslation()
  const [showSnackbar, setShowSnackbar] = React.useState(false);


  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content)
      setShowSnackbar(true)
    } catch (error) {
      alert('Error copying to clipboard:'+ error);
    }
  }

  const hideSnackbar = () => {
    setShowSnackbar(false);
  }


  return (

    <DivRelative>
      {children}

      { visible && content !== "" &&
       <ButtonAbsolute aria-label="copy to cliboard"
                        position={position}
                        onClick={copyCodeToClipboard}>
         <IconCopy fontSize={size} color="primary"/>

         <Snackbar
           anchorOrigin={{ vertical:"bottom", horizontal: "left"}}
           autoHideDuration={3000}
           open={showSnackbar}
           onClose={hideSnackbar}
           message={t(message)}/>

      </ButtonAbsolute>}
    </DivRelative>
  )
}

export default CopyClip

const DivRelative = styled.div`
  position: relative;
  display: flex;
`;

const ButtonAbsolute  = styled.div<{position: string}>`
  position: ${props => props.position};
  cursor: pointer;
  padding: 0 8px;
  ${props => props.position === "absolute" && `
   right: -40px;
   top: 10px;
  `};
`;


