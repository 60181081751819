import {useMutation, useQueryClient} from "@tanstack/react-query";
import ProfileService from "../services/ProfileService";
import {cleanProfile, DocSide, ProfileModel} from "../models/profile.models";
import FileService from "../services/FileService";


const updateProfile = async (profile: ProfileModel): Promise<ProfileModel> => {
  const docFileFront = profile.docPictureFrontFile
  if (docFileFront) {
    await FileService.uploadIDPhoto(DocSide.front, docFileFront, profile.userId)
    profile.docPictureFrontLink = DocSide.front
    profile.docPictureFrontFile = null
  }
  const docFileBack = profile.docPictureBackFile
  if (docFileBack) {
    await FileService.uploadIDPhoto(DocSide.back, docFileBack, profile.userId)
    profile.docPictureBackLink = DocSide.back
    profile.docPictureBackFile = null
  }
  profile = cleanProfile(profile)
  profile.acceptedPrivacy = profile.acceptedTerms
  profile.acceptedSpecialTerms = profile.acceptedTerms

  console.log(profile)
  const { data } = await ProfileService.updateProfile(profile)
  return data
}


const useSaveProfile = (callback:  (profile: ProfileModel) => void) => {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (profile: ProfileModel) => updateProfile(profile),
    onSuccess(result: ProfileModel, profile: ProfileModel) {
      profile.userId = result.userId
      queryClient.invalidateQueries({queryKey: ["profiles"]}).then(result => {
        callback(profile)
      })
    }
  })
}
export default useSaveProfile;

