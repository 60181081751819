import {UseFormRegister, UseFormSetValue} from "react-hook-form";
import {UseFormGetValues} from "react-hook-form/dist/types/form";
import styled from "@emotion/styled";
import {Box, Button, FormControl, FormGroup, InputAdornment, TextField} from "@mui/material";
import React, {FunctionComponent} from "react";
import {CountryModel} from "../../models/geo.models";
import {LoadingButton} from "@mui/lab";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import IconError from "@mui/icons-material/Error";


export interface OptionsProps {
  options: any[]
  optionValue: string
  optionLabel: string
}

export interface FormProps extends StandardTextFieldProps{
  register: UseFormRegister<any>
  getValues: UseFormGetValues<any>
  setValue: UseFormSetValue<any>
  width?: string
  minWidth?: string
  padding?: string
}


export const FlagRenderer = (props: React.HTMLAttributes<HTMLLIElement>, option: CountryModel) => {
  return (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
        alt=""
      />
      {option.name.toUpperCase()}
    </Box>
  )
}

export const FormContainer = styled(FormGroup)`
  gap: 1rem;
  margin-top: 1rem;
`;

export const FormField = styled(FormControl)<{width?: string}>`
  width:  ${props => props.width || "400px"};
`

export const TextLabel = styled(InputAdornment)<{color?: string}>`
  height: initial;
  max-height: initial;
  padding: 0;
  margin-left: 10px;
  p {
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.color || "var(--color-text-dark)"}
  }
`;

export const InputText = styled(TextField)`
  input {
    padding: 10px 16px;
  }
  div {
    border-radius: 6px;
  }
`;

export const ErrorIndicator: FunctionComponent<{error?: boolean, margin?: boolean}> = ({error = false}) => {
    return <IconError color={"error"} sx={{visibility: error ? "visible" : "hidden"}}/>
}


export const ButtonRounded = styled(Button)<{color?: string, fontSize?: string,  size?: "small" | "medium" | "large"}>`
  border-radius: 27px;
  line-height: 27px;
  font-weight: 700;
  color: ${props => props.color === "secondary" ? "var(--color-text-dark)" : "#FFF"};
  text-transform: none;


  ${props => props.size === "small" && `
    padding: 7px 0;
    width: 100px;
    min-width: 100px;
 `};
  
  ${props => props.size === "medium" && `
    padding: 7px 0;
    width: 150px;
    min-width: 150px;
 `};


  
  ${props => !props.size && `
     min-width: 200px;
  `};
  
  ${props => props.fontSize && `
    font-size: ${props.fontSize};
  `};
  
`;


export const LoadingButtonRounded = styled(LoadingButton)<{color?: string, fontSize?: string, width?: string}>`
  border-radius: 27px;
  padding: 7px 20px;
  line-height: 27px;
  font-weight: 700;
  color: ${props => props.color === "secondary" ? "var(--color-text-dark)" : "#FFF"};
  text-transform: none;

  ${props => props.fontSize && `
    font-size: ${props.fontSize};
  `};

  ${props => props.width && `
    width: ${props.width};
    min-width: ${props.width};
 `};

`;

export const H1Center = styled.h1`
  text-align: center;
  white-space: inherit;
`;

export const H2Center = styled.h2`
  text-align: center;
  white-space: inherit;
`;
