import React from "react"
import {Checkbox, FormControlLabel, TextField} from "@mui/material"
import styled from "@emotion/styled"
import {ErrorIndicator, FormField, FormProps} from "./Common"
import {HorizontalBox} from "../Containers"

interface Props extends FormProps {
  fontSize?: string
}

const CheckBoxField: React.FC<Props> = (props) => {

  const {name = "", label, defaultValue = false,
    required, error,
    width = "320px", padding = "0", minWidth = "320px",
    fontSize,
    register, setValue, getValues
  } = props;

  const checked = getValues(name)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue(name, checked, { shouldValidate: true, shouldDirty: checked !== defaultValue })
  }

  return (
    <FormField width={width} sx={{padding: padding, minWidth: minWidth}}>
      <HorizontalBox sx={{alignSelf: "start"}}>

        <TextField sx={{display: "none"}}
                   {...register(name, {required: required}) }/>

        <LabelSelect label={label}
                     fontSize={fontSize}
                     control={<Checkbox checked={checked} onChange={handleChange}/>}/>
        
        <ErrorIndicator error={error}/>
      </HorizontalBox>

    </FormField>
  )

}
export default CheckBoxField

const LabelSelect = styled(FormControlLabel)<{fontSize?: string}>`
  ${props => props.fontSize && `
    font-size: ${props.fontSize};
    span {
      font-size: ${props.fontSize};
    }        
  `};
`



