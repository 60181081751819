import React, {useContext} from 'react';
import './App.css';
import Header from "./lib/components/Header";
import Routing from "./routing/Routing";
import Footer from "./lib/components/Footer";
import styled from "@emotion/styled";
import {AuthContext} from "./lib/AuthContext";
import {UrlsEnum} from "./enums/urls.enum";
import {LabelValueProp} from "./lib/components/transactions/LabelValue";


function App() {

  const authContext = useContext(AuthContext)
  let authenticated = authContext.authenticated

  const headerRoutes : LabelValueProp[] = [
    { label: "button.link.profiles", value: UrlsEnum.PROFILES },
    { label: "button.link.transactions", value: UrlsEnum.TRANSACTIONS }
  ]


  return (
      <AppContainer>
        <Header authenticated={authenticated}
                routes={headerRoutes}>
        </Header>
        <PageContainer>
          <Routing authenticated={authenticated}/>
        </PageContainer>
        <Footer/>
      </AppContainer>
  )
}

export default App;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
`;

const PageContainer  = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



