import React, {useRef, useState} from "react";
import IconUpload from "../images/svg/upload.svg";
import styled from "@emotion/styled";
import {TextNormal} from "./Texts";
import IconClear from "@mui/icons-material/Clear";

interface Props {
  size?: "large" | "medium"
  file?: File | null
  onChanged: (value: File | null) => void
}

const Uploader: React.FunctionComponent<Props> = ({size = "medium", file = null, onChanged}) => {

  const initialPreviewData = file ? URL.createObjectURL(file) : null;

  const [previewData, setPreviewData] = useState<string | null>( initialPreviewData)
  const fileInput = useRef<HTMLInputElement>(null);

  const onInputClick = () => {
    fileInput.current?.click();
  }

  const removeFile = () => {
    setPreviewData(null )
    onChanged(null);
  }

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const file = fileList && fileList[0];
    if (file != null) {
      if (file.type.startsWith("image/")) {
        setPreviewData(URL.createObjectURL(file));
      }
    } else {
      setPreviewData(null )
    }
    onChanged(file);
  }

  return (
      <Container onClick={onInputClick} $size={size} $focusable={previewData === null}>
        {previewData &&
        <>
            <Preview src={previewData}/>
            <Delete onClick={removeFile}>
                <IconClear/>
            </Delete>
            {/*<FileName>{file?.name}</FileName>*/}
        </>}

        {!previewData && <>
            <Image src={IconUpload}/>
            <Upload
                type="file"
                accept={"image/*"}
                name="upload"
                ref={fileInput}
                onChange={onInputChanged}/>
        </>}

      </Container>
  )
}




const Container = styled.div<{$focusable: boolean, $size?: string}>`
  position: relative;
  width: ${props => props.$size === "large" ? "280px" : "200px"};
  height: ${props => props.$size === "large" ? "220px" : "160px"};
  border-radius: 5px;
  border: 1px dashed var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-grigio-chiaro);

  ${props => props.$focusable && `
    &:hover {
      background-color: rgba(186,240,248,1);
      cursor: pointer;
    }
  `};
  
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;


const Upload = styled.input`
  display: none;
`;

const FileName = styled(TextNormal)`
  position: absolute;
  bottom: -10px;
  left: 4px;
  word-break: break-word;
`;

const TextHelp = styled(TextNormal)`
  position: absolute;
  top: 2px;
  left: 4px;
  word-break: break-word;
`;

const Delete = styled(TextNormal)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
  color: var(--color-primary);
  cursor: pointer;
`;

const Preview = styled.img`
  display: flex;
  margin: 4px;
  height: calc(100% - 20px);
  z-index: 5;
  object-fit:scale-down;
  max-width: 100%;
`;


export default Uploader;
