import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {ReactComponent as IconProgress} from "../images/svg/spinner.svg";

interface Props {
  width?: string;
  fill?: 0 | 1;
}

const Spinner: FunctionComponent<Props> = ({width = "120px", fill = 1}) => {

  return (
    <Container $width={width} $fill={fill}>
      <IconProgress/>
    </Container>
  )

}

export default Spinner;

const Container = styled.div<{$width?: string, $fill: number}>`
  width: ${props => props.$width || "100%"};
  display: flex;
  flex: ${props => props.$fill};
  justify-content: center;
  align-items:center;
`;
