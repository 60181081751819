import React, {useState, useImperativeHandle} from "react"
import FormProfilePortrait from "../components/FormProfilePortrait"
import {filterFormData, ProfileModel} from "../lib/models/profile.models"
import {EditMode} from "../lib/models/constants.enum"
import {useNavigate} from "react-router-dom"
import IconLogo from "../lib/images/svg/logo.svg"
import styled from "@emotion/styled"
import {Trans, useTranslation} from "react-i18next"
import FormProfileLandscape from "../components/FormProfileLandscape"
import {useMediaQuery} from "@mui/material"
import {PageCenter} from "../lib/components/Containers"
import Link from "@mui/material/Link"
import {UrlsEnum} from "../enums/urls.enum"
import {H1Center} from "../lib/components/fields/Common"


export type FormProfileHandle = React.ElementRef<typeof FormProfileWrapper>

export type FormProfileInterface = {
  advance: () => void
}

export const privacyLabels = <Trans i18nKey="profile.label.terms">
  <Link target="_blank" href={UrlsEnum.PRIVACY}>PRIVACY</Link>
  <Link target="_blank" href={UrlsEnum.TERMS}>T&C</Link>
</Trans>

interface Props {
  profile: ProfileModel
  mode: EditMode.CREATE | EditMode.UPDATE
  save: (profile: ProfileModel) => void
  saving: boolean,
  errors: any
}

const FormProfileWrapper: React.ForwardRefRenderFunction<FormProfileInterface, Props> = ( {profile, mode, save, saving, errors }, ref) => {

  const portrait = useMediaQuery("(max-width:720px)")
  const maxSteps = portrait ? 4 : 2
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [step, setStep] = useState(0)


  const onNext = (formData: ProfileModel) => {
    const { changed, filtered } = filterFormData(step, maxSteps, portrait, formData, profile)
    if (changed) {
      if (profile.userId) {
        filtered.userId = profile.userId
      }
      console.log("------>  needs update at step "+step)
      save(filtered)

    } else {
      console.log("------> skipping update at step "+step)
      goNext()
    }
  }
  const onBack = () => {
    if (step > 0) {
      setStep(step - 1)
    } else {
      navigate(-1)
    }
  }

  const goNext = () => {
    if (step < maxSteps) {
      setStep(step + 1)
    }
  }

  const gotoProfiles = () => {
    navigate(UrlsEnum.PROFILES, {replace: true})
  }

  useImperativeHandle(ref, () => ({
    advance() {
      goNext()
    }
  }))


  if (step === maxSteps) {
    return (
      <PageCenter>
        <Logo src={IconLogo}/>
        <br/>
        <H1Center>{t("profile.text."+mode)}</H1Center>
        <br/>
        <Link variant={"body1"}
              sx={{cursor: "pointer"}}
              onClick={gotoProfiles}>
          {t("profile.text.complete")}
        </Link>
      </PageCenter>
    )
  }

  return (
    <>
      {portrait && <FormProfilePortrait mode={mode}
                                       profile={profile}
                                       step={step}
                                       maxSteps={maxSteps}
                                       onBack={onBack}
                                       onNext={onNext}
                                       saving={saving}
                                       errors={errors} />}

      {!portrait && <FormProfileLandscape mode={mode}
                                         profile={profile}
                                         step={step}
                                         maxSteps={maxSteps}
                                         onBack={onBack}
                                         onNext={onNext}
                                         saving={saving}
                                         errors={errors} />}


    </>
  )
}

export default React.forwardRef(FormProfileWrapper)


const Logo = styled.img`
  height: 170px
`
