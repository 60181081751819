import React, {FunctionComponent} from "react";
import {CenterFull, HorizontalBox, SpaceBox, Warning} from "../lib/components/Containers";
import FormLogin from "../components/FormLogin";
import {UserLogin} from "../lib/models/user.models";
import useLogin from "../lib/hooks/useLogin";
import {Banner} from "../lib/components/Banner";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useMediaQuery} from "@mui/material";
import {cleanupData} from "../lib/utils/Functions";
import {LOGIN_TYPE} from "../Config";


const Login: FunctionComponent = () => {

  const {mutate: login, isPending, error} = useLogin()
  const portrait = useMediaQuery("(max-width:640px)")

  const { t } = useTranslation()

  const formLogin = (user: UserLogin) => {
    user = cleanupData(user, ["username", "password"]) as unknown as UserLogin
    user.userType = LOGIN_TYPE
    login(user)
  }

  return (
    <>
      <SpaceBox size={portrait ? 50 : 120}/>

      <HorizontalBox>
        <Banner size={portrait ? "small" : "medium"}/>
      </HorizontalBox>

      <SpaceBox size={portrait ? 0 : 50}/>

      <CenterFull direction={"column"} justify={"space-around"}>

        <Container portrait={portrait}>

          <SpaceBox size={portrait ? 0 : 28}/>

          <h1 style={{color: "#2E62FE"}}>{t("login.title.welcome")}</h1>
          <SpaceBox size={8}/>
          <h2 style={{color: "#2E62FE", whiteSpace:"inherit", textAlign:"center"}}>{t("login.title.instructions")}</h2>

          <FormLogin onSubmit={formLogin} pending={isPending} fieldWidth={portrait ? "300px" : "400px"}/>

          <Warning style={{visibility: error ? "visible": "hidden"}} severity={"error"}>{""+error}</Warning>

        </Container>

    </CenterFull>
    </>
  )
}

export default Login

export const Container = styled.div<{portrait: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  /*width: 620px;
  height: 500px;*/
  ${props => props.portrait && `
  `}
  
  ${props => !props.portrait && `
    padding: 22px 68px;
    border: 1px solid var(--color-primary);
    border-radius: 9px; 
  `}
`;