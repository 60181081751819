import React from 'react';
import LabelValue from "./LabelValue";
import {Alert, Box} from "@mui/material";
import {getRecipient, ProfileModel} from "../../models/profile.models";
import Spinner from "../Spinner";

interface Props {
  label: string
  identifier?: string
  profile?: ProfileModel
  isSuccess: boolean
  isPending: boolean
  error?: any
}

const BoxTransUser: React.FunctionComponent<Props> = ({label, profile, identifier, isSuccess, isPending, error}) => {

  return (
    <Box>
      { isSuccess &&
        <>
          <LabelValue label={label} value={getRecipient(profile)}/>
          <LabelValue label={"Transaction Id"} value={identifier || ""}/>
        </>}
      { isPending &&
        <Spinner fill={1}/>}
      { error &&
        <Alert severity={"error"}>{error}</Alert>}
      { !isPending && !isSuccess &&
        <LabelValue label={""} value={"N/A"}/>}
    </Box>
  )

}

export default BoxTransUser;

