import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter, SpaceBox} from "../Containers";
import CheckSelect from "../fields/CheckSelect";
import {ProfileModel} from "../../models/profile.models";
import {FormContainer, H2Center} from "../fields/Common";
import useListProfiles from "../../hooks/useListProfiles";
import {QueryMode} from "../../models/constants.enum";

interface Props {
  buyer: ProfileModel | null,
  seller: ProfileModel | null,
  onChanged: (buyer: ProfileModel | null, seller: ProfileModel | null) => void
}

const FormTransParty: FunctionComponent<Props> = ({buyer, seller, onChanged}) => {

  const { data: profiles } = useListProfiles(QueryMode.OPERATOR)
  const { t } = useTranslation()


  const onBuyerChanged = (profile: ProfileModel | null) => {
    onChanged(profile, seller)
  }
  const onSellerChanged = (profile: ProfileModel | null) => {
    onChanged(buyer, profile)
  }

  return (

    <ColumnCenter>

      <H2Center>{t("transaction.create.step0.text")}</H2Center>

      <FormContainer>

        <SpaceBox size={4}/>


        <CheckSelect<ProfileModel>
          label={t("transaction.partyType.seller")}
          value={seller || ""}
          options={profiles || []}
          optionValue="userId"
          getOptionLabel={ (profile) => profile.firstName+" "+profile.lastName}
          filter={buyer}
          emptyText = {t("select.placeholder.text")}
          onSelectionChanged={onSellerChanged}/>

        <SpaceBox size={4}/>

        <CheckSelect<ProfileModel>
          label={t("transaction.partyType.buyer")}
          value={buyer || ""}
          options={profiles || []}
          optionValue="userId"
          filter={seller}
          getOptionLabel={ (profile) => profile.firstName+" "+profile.lastName}
          emptyText = {t("select.placeholder.text")}
          onSelectionChanged={onBuyerChanged}/>

      </FormContainer>

    </ColumnCenter>
  )
}

export default FormTransParty


