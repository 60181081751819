import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {BorderBox, MarginBox, Column} from "../Containers";
import InputTextField from "../fields/InputTextField";
import {useForm} from "react-hook-form";
import {ProfileDataPersonalResidence, getSexOptions} from "../../models/profile.models";
import PhoneField from "../fields/PhoneField";
import {FlagRenderer, FormContainer} from "../fields/Common";
import {Box} from "@mui/material";
import AutoCompleteField from "../fields/AutoCompleteField";
import {CityModel, CountryModel, ProvinceModel} from "../../models/geo.models";
import NumericField from "../fields/NumericField";
import useGeo from "../../hooks/useGeo";
import Auth from "../../services/Auth";
import CheckBoxField from "../fields/CheckBoxField";
import {privacyLabels} from "../../../components/FormProfileWrapper";
interface Props {
  profile: ProfileDataPersonalResidence
  onChanged: (profile: ProfileDataPersonalResidence, valid: boolean) => void
}

const FormPersonalResidence: FunctionComponent<Props> = ({profile, onChanged}) => {

  const { t } = useTranslation()
  const { register, watch,
    formState: { errors, isValid },
    control, getValues, setValue
  } = useForm({ defaultValues: profile, mode: "all" });

  const {countries, provinces, useCities} = useGeo();
  const [residenceProvinceName, setResidenceProvinceName] = useState<string | undefined>()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const cf = watch("cf")
  const firstName = watch("firstName")
  const lastName = watch("lastName")
  const middleNames = watch("middleNames")
  const sex = watch("sex")
  const phone = watch("phone")
  const phoneCountryCode = watch("phoneCountryCode")

  const residenceCountry = watch("residenceCountry")
  const residenceProvince = watch("residenceProvince")
  const residenceCity = watch("residenceCity")
  const residenceAddress = watch("residenceAddress")
  const residenceCAP = watch("residenceCAP")
  const acceptedTerms = watch("acceptedTerms")

  const cities = useCities(residenceProvinceName)
  const comuni = cities.isFetched ? cities.data || [] : []

  useEffect(() => {
    const provincia = provinces.data?.find(item => item.sigla === residenceProvince)
    if (provincia) {
      setResidenceProvinceName(provincia?.nome)
      if (firstLoad) {
        setFirstLoad(false)
      } else {
        setValue("residenceCity", "")
      }
    }
  }, [provinces.isFetched, residenceProvince])

  useEffect(() => {
    if (residenceCountry !== "it") {
      setValue("residenceProvince", "")
    }
  }, [residenceCountry])


  useEffect(() => {
    onChanged(getValues(), isValid)
  }, [cf, firstName, lastName, middleNames, sex, phone, phoneCountryCode, residenceCountry, residenceProvince, residenceCity, residenceAddress, residenceCAP, acceptedTerms, isValid])

  return (
    <Column>
      <FormContainer>
        <BorderBox padding={"30px 0"}>
          <MarginBox left={"40px"} bottom={"20px"}><h3>{t("profile.title.personal")}</h3></MarginBox>
          <Box mb={"20px"}>
            <InputTextField name={"firstName"}
                            width={"40%"}
                            padding={"0 10px 0 40px"}
                            label={t("profile.label.firstName")}
                            required
                            error={!!errors["firstName"]}
                            register={register}
                            uppercase={true}
                            placeholder={t("profile.placeholder.firstName")}/>

            <InputTextField name={"middleNames"}
                            width={"20%"}
                            label={t("profile.label.middleNames")}
                            error={!!errors["middleNames"]}
                            register={register}
                            uppercase={true}
                            placeholder={t("profile.placeholder.middleNames")}/>

            <InputTextField name={"lastName"}
                            width={"39%"}
                            padding={"0 0 0 10px"}
                            label={t("profile.label.lastName")}
                            required
                            error={!!errors["lastName"]}
                            register={register}
                            uppercase={true}
                            placeholder={t("profile.placeholder.lastName")}/>
          </Box>
          <Box>
            <InputTextField name={"cf"}
                            width={"40%"}
                            padding={"0 10px 0 40px"}
                            label={t("profile.label.cf")}
                            error={!!errors["cf"]}
                            required
                            maxLength={16}
                            register={register}
                            uppercase={true}
                            placeholder={t("profile.placeholder.cf")}/>

            <AutoCompleteField<string> name={"sex"}
                                       width={"20%"}
                                       label={t("profile.label.sex")}
                                       optionValue={"value"}
                                       optionLabel={"label"}
                                       initialValue={profile.sex}
                                       register={register}
                                       control={control}
                                       error={!!errors["sex"]}
                                       uppercase={true}
                                       placeholder={t("select.placeholder.text")}
                                       autocompleteProps={{
                                         autoHighlight: true,
                                         noOptionsText: t("select.miss.text"),
                                         options: getSexOptions(t)
                                       }}/>

            {!Auth.isOperatorMode() &&
              <PhoneField name={"phone"}
                          width={"39%"}
                          padding={"0 0 0 10px"}
                          nameCountryCode={"phoneCountryCode"}
                          label={t("profile.label.phone")}
                          required
                          setValue={setValue}
                          getValues={getValues}
                          defaultValue={profile.phone || ""}
                          defaultCountryCodeValue={profile.phoneCountryCode || ""}
                          error={!!errors["phone"]}
                          register={register}
                          placeholder={t("profile.placeholder.phone")}/>}
          </Box>
        </BorderBox>

        <BorderBox padding={"30px 0"} marginBottom={"0"}>
          <MarginBox left={"40px"} bottom={"20px"}><h3>{t("profile.title.residence")}</h3></MarginBox>

          <Box mb={"20px"}>
            <AutoCompleteField<CountryModel> name={"residenceCountry"}
                                             width={ residenceCountry === "it" ? "33%" : "50%"}
                                             padding={"0 10px 0 40px"}
                                             label={t("profile.label.residenceCountry")}
                                             optionValue={"alpha2"}
                                             optionLabel={"name"}
                                             initialValue={profile.residenceCountry || ""}
                                             register={register}
                                             control={control}
                                             error={!!errors["residenceCountry"]}
                                             uppercase={true}
                                             placeholder={t("profile.placeholder.residenceCountry")}
                                             autocompleteProps={{
                                               options: countries.data || [],
                                               autoHighlight: true,
                                               noOptionsText: t("select.miss.text"),
                                               renderOption: FlagRenderer
                                            }}/>


            { residenceCountry === "it" &&
              <AutoCompleteField<ProvinceModel> name={"residenceProvince"}
                                                width={"33%"}
                                                padding={"0 10px 0 0"}
                                                label={t("profile.label.residenceProvince")}
                                                optionValue="sigla"
                                                optionLabel="nome"
                                                initialValue={profile.residenceProvince || ""}
                                                register={register}
                                                control={control}
                                                error={!!errors["residenceProvince"]}
                                                uppercase={true}
                                                placeholder={t("profile.placeholder.residenceProvince")}
                                                autocompleteProps={{
                                                  options: provinces.data || [],
                                                  autoHighlight: true,
                                                  noOptionsText: t("select.miss.text")
                                                }}/>}

            <AutoCompleteField<CityModel> name={"residenceCity"}
                                          width={ residenceCountry === "it" ? "33%" : "50%"}
                                          padding={"0 10px 0 0"}

                                          label={t("profile.label.residenceCity")}
                                          optionValue={"nome"}
                                          optionLabel={"nome"}
                                          initialValue={profile.residenceCity || ""}
                                          register={register}
                                          control={control}
                                          error={!!errors["residenceCity"]}
                                          uppercase={true}
                                          placeholder={t("profile.placeholder.residenceCity")}
                                          autocompleteProps={{
                                            options: residenceCountry === "it" ? comuni : [],
                                            autoHighlight: true,
                                            noOptionsText: t("select.miss.text"),
                                            freeSolo: residenceCountry !== "it" || residenceProvince === undefined
                                          }}/>
            </Box>
            <Box>
              <InputTextField name={"residenceAddress"}
                              width={"50%"}
                              padding={"0 10px 0 40px"}
                              label={t("profile.label.residenceAddress")}
                              error={!!errors["residenceAddress"]}
                              required
                              register={register}
                              uppercase={true}
                              placeholder={t("profile.placeholder.residenceAddress")}/>

              <NumericField name={"residenceCAP"}
                            width={"50%"}
                            padding={"0 6px 0 0"}
                            label={t("profile.label.residenceCap")}
                            error={!!errors["residenceCAP"]}
                            required
                            register={register}
                            setValue={setValue}
                            defaultValue={profile.residenceCAP}
                            placeholder={t("profile.placeholder.cap")}/>
            </Box>
          </BorderBox>

          <CheckBoxField name={"acceptedTerms"}
                         label={privacyLabels}
                         fontSize={"16px"}
                         width={"100%"}
                         padding={"0 0 16px 8px"}
                         defaultValue={profile.acceptedTerms}
                         error={!!errors["acceptedTerms"]}
                         required
                         getValues={getValues}
                         setValue={setValue}
                         register={register}/>
      </FormContainer>
    </Column>
  )
}

export default FormPersonalResidence