import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import InputTextField from "../fields/InputTextField";
import {useForm} from "react-hook-form";
import {ProfileDataPersonal} from "../../models/profile.models";
import RadioField from "../fields/RadioField";
import PhoneField from "../fields/PhoneField";
import {FormContainer} from "../fields/Common";
import Auth from "../../services/Auth";
import CheckBoxField from "../fields/CheckBoxField";
import {privacyLabels} from "../../../components/FormProfileWrapper";

const getSexOptions = (t:any) => {
  return [
    { value: "M", label: t("profile.sex.male")},
    { value: "F", label: t("profile.sex.female")}
  ]
}

interface Props {
  profile: ProfileDataPersonal
  onChanged: (profile: ProfileDataPersonal, valid: boolean, dirty: boolean) => void
}

const FormPersonal: FunctionComponent<Props> = ({profile, onChanged}) => {

  const { t } = useTranslation()
  const { register, watch,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues, setValue
  } = useForm({ defaultValues: profile, mode: "all" });

  const cf = watch("cf")
  const firstName = watch("firstName")
  const lastName = watch("lastName")
  const middleNames = watch("middleNames")
  const sex = watch("sex")
  const phone = watch("phone")
  const phoneCountryCode = watch("phoneCountryCode")
  const acceptedTerms = watch("acceptedTerms")

  useEffect(() => {
    onChanged(getValues(), isValid, isDirty)
  }, [cf, firstName, lastName, middleNames, sex, phone, phoneCountryCode, acceptedTerms, isValid])

  //console.log("isDirty="+isDirty+" "+JSON.stringify(dirtyFields))

  return (
    <ColumnCenter>

      <h3>{t("profile.title.personal")}</h3>

      <FormContainer>
        <InputTextField name={"cf"}
                        label={t("profile.label.cf")}
                        error={!!errors["cf"]}
                        required
                        maxLength={16}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.cf")}/>

        <InputTextField name={"firstName"}
                        label={t("profile.label.firstName")}
                        required
                        error={!!errors["firstName"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.firstName")}/>

        <InputTextField name={"middleNames"}
                        label={t("profile.label.middleNames")}
                        error={!!errors["middleNames"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.middleNames")}/>

        <InputTextField name={"lastName"}
                        label={t("profile.label.lastName")}
                        required
                        error={!!errors["lastName"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.lastName")}/>

        <RadioField name={"sex"}
                    label={t("profile.label.sex")}
                    required
                    defaultValue={profile.sex || ""}
                    setValue={setValue}
                    getValues={getValues}
                    error={!!errors["sex"]}
                    register={register}
                    optionLabel={"label"}
                    optionValue={"value"}
                    options={getSexOptions(t)} />

        {!Auth.isOperatorMode() &&
          <PhoneField name={"phone"}
                      nameCountryCode={"phoneCountryCode"}
                      label={t("profile.label.phone")}
                      required
                      setValue={setValue}
                      getValues={getValues}
                      defaultValue={profile.phone || ""}
                      defaultCountryCodeValue={profile.phoneCountryCode || ""}
                      error={!!errors["phone"]}
                      register={register}
                      placeholder={t("profile.placeholder.phone")}/>}

        <CheckBoxField name={"acceptedTerms"}
                       label={privacyLabels}
                       padding={"0 16px 16px 0"}
                       fontSize={"16px"}
                       defaultValue={profile.acceptedTerms}
                       error={!!errors["acceptedTerms"]}
                       required
                       getValues={getValues}
                       setValue={setValue}
                       register={register}/>

      </FormContainer>




    </ColumnCenter>
  )
}

export default FormPersonal