import {TokenModel} from "../models/user.models";
import {setAuthToken} from "./http";

class Auth {

  private static instance: Auth

  private token: TokenModel | null  = null

  private constructor() {
    this.token = this.loadToken();
  }

  private static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private loadToken = (): TokenModel | null => {
    let token = null
    let data = localStorage.getItem("token");
    if (data !== null) {
      try {
        token = JSON.parse(data)
        setAuthToken(token.accessToken, token.userType);
      } catch (err) {
        console.error("failed loading from " + data)
      }
    }
    return token;
  }

  public static getToken = () => {
    return Auth.getInstance().token
  }

  public static saveToken = (token: TokenModel) => {
    Auth.getInstance().token = token
    localStorage.setItem("token", JSON.stringify(token));
    setAuthToken(token.accessToken, token.userType);
  }

  public static clearToken = () => {
    Auth.getInstance().token = null
    localStorage.removeItem("token");
    setAuthToken(null, null);
  }

  public static getOperatorKey = () => {
    return Auth.getInstance().token?.operatorKey || null
  }

  public static isOperatorMode = () => {
    return Auth.getOperatorKey() !== null
  }

}

export default Auth