import React, {FunctionComponent, useState} from 'react'
import styled from "@emotion/styled"
import {useTranslation} from "react-i18next"
import Spinner from "../lib/components/Spinner"
import {useNavigate} from "react-router-dom"
import {UrlsEnum} from "../enums/urls.enum"
import {
  isMatchableTransaction,
  isSellerBuyerTransaction,
  TransactionModel,
  TransactionStates
} from "../lib/models/transactionModel"
import useListTransactions from "../lib/hooks/useListTransactions"
import {MenuItem, Select, TableBody} from "@mui/material"
import IconAdd from "@mui/icons-material/Add"
import TopBar from "../components/TopBar"
import {CenterFull, PageList, TableData, TableScroll, ToolbarWide, Warning, Line} from "../lib/components/Containers"
import {ButtonRounded} from "../lib/components/fields/Common"
import RowTransaction from "../lib/components/transactions/RowTransaction"
import {QUERY_MODE, MERGE_DUAL_TRANSACTIONS} from "../Config";


const TransactionList: FunctionComponent = () => {

  const [filterPlate, setFilterPlate] = useState("");
  const [filterState, setFilterState] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: transactions, isSuccess, isPending, isError, error } = useListTransactions(QUERY_MODE)

  const predicateFilterPlate = (item: TransactionModel) => {
    return filterPlate === "" ? true : item.licensePlates.toUpperCase().indexOf(filterPlate.toUpperCase()) > -1;
  }

  const predicateFilterState = (item: TransactionModel) => {
    return filterState === 0 ? true : item.state === filterState;
  }




  const gotoCreateTransaction = () => {
    navigate(UrlsEnum.TRANSACTION_CREATE)
  }

  const gotoViewTransaction = (transaction: TransactionModel) => {
    navigate(UrlsEnum.TRANSACTIONS+"/"+transaction.id+"/view")
  }

  const transStates = ["transaction.label.all.states"].concat(TransactionStates).map( (state, index)  => {
    return (
      <MenuItem key={index} value={index}>{t(state)}</MenuItem>
    )});

  let filteredTransactions = transactions || []
  if (MERGE_DUAL_TRANSACTIONS) {
    filteredTransactions = filteredTransactions.filter( (oneItem => {
      const otherTransactions = filteredTransactions.filter( (item => item !== oneItem))
      const isMatchingBuyer = otherTransactions.some( (otherItem => isSellerBuyerTransaction(oneItem, otherItem)))
      const isMatching =  otherTransactions.some( (otherItem => isMatchableTransaction(oneItem, otherItem)))
      return isMatchingBuyer || !isMatching
    }))
  }

  const transItems = filteredTransactions.filter(predicateFilterPlate).filter(predicateFilterState).map((transaction) => {


    return (
      <RowTransaction key={transaction.id} transaction={transaction} onAction={gotoViewTransaction}/>
    )});

  return (
    <>
      <PageList>

        <TopBar title={t("transaction.header.list")}
                justify={"space-between"}
                searchPlaceholder={t("transaction.label.plate")}
                searchEnabled={transactions && transactions.length > 0}
                onSearchTextChanged={setFilterPlate}>
          {transactions && transactions.length > 0 &&
            <SelectStates variant={"outlined"}
                          value={filterState}
                          onChange={(event) => {
                            setFilterState(event.target.value as number);
                         }}>
              {transStates}
            </SelectStates>}
        </TopBar>

        {isSuccess && transactions.length === 0 &&
          <Warning severity="info">{t("transaction.text.empty")}</Warning>}

        <TableScroll elevation={0}>
          <TableData>
            <TableBody>
              { transItems }
            </TableBody>
          </TableData>
        </TableScroll>

        {isPending &&
        <CenterFull>
          <Spinner />
        </CenterFull>}

        {isError &&
        <Warning severity="warning">{error}</Warning>}

      </PageList>
      {isSuccess &&
        <>
          <Line/>
          <ToolbarWide justify={"end"}>
            <ButtonRounded onClick={gotoCreateTransaction} variant="contained" endIcon={<IconAdd />}>
              {t("transaction.header.create")}
            </ButtonRounded>
          </ToolbarWide>
        </>}
    </>
  )
}
export default TransactionList;

const SelectStates = styled(Select)`
  border-radius: 6px;
  min-width: 160px;
`;
