import React from 'react';
import styled from "@emotion/styled";
import {TextSmall} from "../Texts";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell,TableHead, TableRow} from "@mui/material";
import {ProfileModel} from "../../models/profile.models";
import moment from "moment/moment";
import Link from "@mui/material/Link";


const decodeColor = (profile: ProfileModel) => {
  if (profile.profileVerified) {
    return "#2E62FE";
  } else if (profile.userComplete) {
    return "#1DC275";
  } else {
    return "#F0B61F";
  }
}


const decodeState = (profile: ProfileModel) => {
  if (profile.profileVerified) {
    return "Verificato";
  } else if (profile.userComplete) {
    return "In attesa di verifica";
  } else {
    return "Incompleto";
  }
}


interface Props {
  profile: ProfileModel,
  onAction: (profile: ProfileModel) => void
}


const RowProfile: React.FunctionComponent<Props> = ({profile, onAction}) => {

  const { t } = useTranslation();

  const onActionClicked = () => {
    onAction(profile);
  }

  return (
    <TableRow id={"profile-"+profile.id}>
      <CellOuter>
        <TableInner className={"responsive"}>
          <TableHead>
            <TableRow>
              <CellHead width={"25%"} minwidth={"200px"} component={"th"}>{t("profile.row.code")}</CellHead>
              <CellHead width={"25%"} minwidth={"200px"} component={"th"}>{t("profile.row.name")}</CellHead>
              <CellHead minwidth={"100px"} component={"th"}>{t("profile.row.date")}</CellHead>
              <CellHead minwidth={"100px"} component={"th"}>{t("profile.row.state")}</CellHead>
              <CellHead width={"auto"}>&nbsp;</CellHead>
           </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <Cell data-label={t("profile.row.code")}>{profile.cf}</Cell>
              <Cell data-label={t("profile.row.name")}>{profile.firstName} {profile.lastName}</Cell>
              <Cell data-label={t("profile.row.date")}>
                { moment(profile.creationDate, "YYYYMMDD").format(moment.localeData().longDateFormat('L'))}
              </Cell>
              <Cell data-label={t("profile.row.state")}>
                <TextSmallBoxed boxColor={decodeColor(profile)}>{t(decodeState(profile))}</TextSmallBoxed>
              </Cell>
              <Cell align={"right"} width={"auto"}>
                <Link onClick={onActionClicked} variant={"body1"} sx={{fontWeight: 700, cursor: "pointer"}}>Link</Link>
              </Cell>
            </TableRow>
          </TableBody>
        </TableInner>
      </CellOuter>
    </TableRow>
  )

}

export default RowProfile;



const CellOuter = styled(TableCell)`
  border-radius: 6px;
  border-bottom: inherit;
  border: 1px solid var(--color-grigio-scuro);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 18px 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 360px) {
    padding: 12px 16px 10px 16px;
  }
`;

const TableInner = styled(Table)`
  padding: 0;
  margin: 0;
  border: 0;
`;

const CellHead = styled(TableCell)<{width? :string, minwidth?: string}>`
  padding: 0;
  border: 0;
  min-width: ${props => props.minwidth || "auto"};
  width: ${props => props.width || "auto"};
  font-size: 14px;
  color: var(--color-text-dark);
  text-transform: uppercase;
`;

const Cell = styled(TableCell)`
  padding: 0;
  border: 0;
  font-size: 18px;
  color: var(--color-text-dark);
`;

const TextSmallBoxed = styled(TextSmall)<{boxColor: string}>`
  color: white;
  border-radius: 2px;
  background-color: ${props => props.boxColor};
  padding: 4px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
`;
