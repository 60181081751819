import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import "./common/i18n";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ThemeProvider} from "@mui/material/styles";
import {AppTheme} from "./common/theme";
import {AuthProvider} from "./lib/AuthContext";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({defaultOptions: { queries: {refetchOnWindowFocus: false}}})

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
);


