import React, {FunctionComponent} from "react";
import IconLogo from "../images/svg/logo.svg";
import IconPleggit from "../images/svg/pleggit-title.svg";
import styled from "@emotion/styled";
import {SpaceBox} from "./Containers";

interface Props {
  onClick?: () => void
  size?: "small" | "medium"
}

export const Banner: FunctionComponent<Props> = ({size = "small" , onClick}) => {
  return (
    <Centered>
      <Logo size={size} src={IconLogo}/>
      { size === "medium" &&
        <SpaceBox size={20}/>}
      <Pleggit size={size} src={IconPleggit} pointer={onClick} onClick={onClick}/>
    </Centered>
  )
}


const Centered = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img<{size: string}>`
  ${props => props.size === "small" && `
     height: 50px;
  `};
`;

const Pleggit = styled.img<{size: string, pointer?:any}>`
  ${props => props.size === "small" && `
    margin-top: 5px;
    height: 35px;
  `};
  ${props => props.pointer && `
     cursor: pointer;
  `};
`;
