export enum TransactionDocType {
  pp = "propertyproof",
  pt ="propertytransfer"
}

export const TransactionPartyType = {
  seller: "seller",
  buyer: "buyer"
}

export interface TransactionType {
  id: string,
  icon: string,
  i18n: string,
}
export const TransactionTypes: TransactionType[] = [
  { id: "car", icon: "/images/png/auto.png", i18n: "transaction.itemType.car"},
  { id: "scooter", icon: "/images/png/moto.png", i18n: "transaction.itemType.scooter"},
  { id: "truck", icon: "/images/png/camion.png", i18n: "transaction.itemType.truck"},
  { id: "camper", icon: "/images/png/camper.png", i18n: "transaction.itemType.camper"}
]

export const TransactionStates = [
  "transaction.state.creation",
  "transaction.state.matching",
  "transaction.state.payment",
  "transaction.state.transfer",
  "transaction.state.checks",
  "transaction.state.completed"
]

export interface TransactionModel {
  id: string,
  partyType: "seller" | "buyer",
  itemType: string,
  name: string,

  user: string,

  licensePlates: string,
  price: number,
  currency: string,
  iban: string,

  state: number,
  stateCode: string,
  creationTime: string,

  match?: string
  exchangeCode?: string
  propertyProofURL?: string
  propertyTransURL?: string
  counterpartInsertedEcode?: boolean
  counterpartECode?: string

  pcode?: string
}

export interface TransCreateData {
  licensePlates: string,
  price: string,
  currency: string,
  iban?: string,
}

export interface TransCreateModel extends TransCreateData {
  partyType: string,
  itemType: string,
  file?: File,

  user: string,
  operatorKey?: string
}


const isPartyType = (partyType: string, transaction?: TransactionModel) => {
  return (transaction && (transaction.partyType === partyType)) || false
}

export const isBuyerTransaction = (transaction?: TransactionModel): boolean => {
  return isPartyType(TransactionPartyType.buyer, transaction)
}

export const isSellerTransaction = (transaction?: TransactionModel): boolean => {
  return isPartyType(TransactionPartyType.seller, transaction)
}


export const isMatchableTransaction = (selling: TransactionModel, buying: TransactionModel): boolean => {
  return selling.licensePlates === buying.licensePlates && selling.price === buying.price && selling.currency === buying.currency
}
export const isSellerBuyerTransaction = (selling: TransactionModel, buying: TransactionModel): boolean => {
  return isMatchableTransaction(selling, buying)
    && selling.partyType === TransactionPartyType.seller && buying.partyType === TransactionPartyType.buyer
}

/* Fields have to follow this strict convention:
*  - partyType         : ('buyer', 'seller')
*  - itemType          : ('scooter', 'car', 'bike', 'boat', 'airplane', 'other')
*  - name              : transaction name
*  - licensePlates     : plates
*  - price             : price
*  - currency          : currency (EUR, etc..)
*  - iban              : iban
*/


/*
state 1
{
    "id": "6512349f5c851c726249d8bd",
    "creationTime": 20230926033215,
    "partyType": "seller",
    "itemType": "moto",
    "licensePlates": "1",
    "price": 1,
    "currency": "EUR",
    "deposit": null,
    "iban": "1",
    "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
    "state": 1,
    "stateCode": "creation",
    "canCancel": true,
    "canWithdraw": false
}

state 2
{
    "id": "65146a6c5c851c72626c1f2e",
    "creationTime": 20230927194620,
    "partyType": "seller",
    "itemType": "moto",
    "licensePlates": "FRRF4RF",
    "price": 2344,
    "currency": "EUR",
    "deposit": null,
    "iban": "4324332fed",
    "propertyProofURL": "b100fa3e-705a-497b-a494-deceb2330cb0/65146a6c5c851c72626c1f2e/propertyproof",
    "ppOCRPass": false,
    "ppOCRMatches": [],
    "ppOCRConfidence": 0,
    "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
    "state": 2,
    "stateCode": "matching",
    "canCancel": true,
    "canWithdraw": false
},
state 3
{
    "id": "65288cdd5c851c7262a4b124",
    "creationTime": 20231013021837,
    "partyType": "seller",
    "itemType": "scooter",
    "licensePlates": "JSHDH",
    "price": 64918,
    "currency": "EUR",
    "deposit": null,
    "iban": "GB33BUKB20201555555555",
    "propertyProofURL": "b100fa3e-705a-497b-a494-deceb2330cb0/65288cdd5c851c7262a4b124/propertyproof",
    "ppOCRPass": false,
    "ppOCRMatches": [],
    "ppOCRConfidence": 0,
    "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
    "match": "63ab2c5f5bc6ebd243da740b",
    "pcode": "JSHDH-549HDP",
    "state": 3,
    "stateCode": "payment",
    "canCancel": true,
    "canWithdraw": false
}
state 4
{
    "id": "65288cdd5c851c7262a4b124",
    "creationTime": 20231013021837,
    "partyType": "seller",
    "itemType": "scooter",
    "licensePlates": "JSHDH",
    "price": 64918,
    "currency": "EUR",
    "deposit": null,
    "iban": "GB33BUKB20201555555555",
    "exchangeCode": "Y4X4",
    "transferExpiry": 20231112,
    "propertyProofURL": "b100fa3e-705a-497b-a494-deceb2330cb0/65288cdd5c851c7262a4b124/propertyproof",
    "ppOCRPass": false,
    "ppOCRMatches": [],
    "ppOCRConfidence": 0,
    "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
    "match": "63ab2c5f5bc6ebd243da740b",
    "pcode": "JSHDH-549HDP",
    "buyerPaid": true,
    "buyerPaidOn": 20231013,
    "state": 4,
    "stateCode": "transfer",
    "canCancel": false,
    "canWithdraw": true


    "counterpartInsertedEcode": true,

    "counterpartECode": "304P",


}

state 5
{
            "id": "651625925c851c72628720b0",
            "creationTime": 20230929031706,
            "partyType": "seller",
            "itemType": "camper",
            "licensePlates": "132",
            "price": 1,
            "currency": "EUR",
            "deposit": null,
            "iban": "1",
            "exchangeCode": "I2C6",
            "transferExpiry": 20231113,
            "counterpartECode": "YMIN",
            "propertyProofURL": "b100fa3e-705a-497b-a494-deceb2330cb0/651625925c851c72628720b0/propertyproof",
            "propertyTransURL": "b100fa3e-705a-497b-a494-deceb2330cb0/651625925c851c72628720b0/propertyproof",
            "ptOCRPass": false,
            "ptOCRMatches": [],
            "ptOCRConfidence": 0,
            "ppOCRPass": false,
            "ppOCRMatches": [],
            "ppOCRConfidence": 0,
            "user": "b100fa3e-705a-497b-a494-deceb2330cb0",
            "match": "6529e2715c851c7262b9ee4c",
            "pcode": "132-DB1I2Z",
            "buyerPaid": true,
            "buyerPaidOn": 20231014,
            "counterpartInsertedEcode": true,
            "state": 5,
            "stateCode": "checks",
            "canCancel": false,
            "canWithdraw": false
        },
 */