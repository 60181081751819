import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {useForm} from "react-hook-form";
import {ProfileDataResidence} from "../../models/profile.models";
import {CityModel, CountryModel, ProvinceModel} from "../../models/geo.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import useGeo from "../../hooks/useGeo";
import InputTextField from "../fields/InputTextField";
import {FlagRenderer, FormContainer} from "../fields/Common";
import NumericField from "../fields/NumericField";


interface Props {
  profile: ProfileDataResidence
  onChanged: (profile: ProfileDataResidence, valid: boolean, dirty: boolean) => void
}

const FormResidence: FunctionComponent<Props> = ({profile, onChanged}) => {

  const {t} = useTranslation()
  const {register, watch,
    formState: {errors, isValid, isDirty},
    control, getValues, setValue
  } = useForm({defaultValues: profile, mode: "all"});

  const {countries, provinces, useCities} = useGeo();
  const [residenceProvinceName, setResidenceProvinceName] = useState<string | undefined>()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const residenceCountry = watch("residenceCountry")
  const residenceProvince = watch("residenceProvince")
  const residenceCity = watch("residenceCity")
  const residenceAddress = watch("residenceAddress")
  const residenceCAP = watch("residenceCAP")

  const cities = useCities(residenceProvinceName)
  const comuni = cities.isFetched ? cities.data || [] : []

  useEffect(() => {
    const provincia = provinces.data?.find(item => item.sigla === residenceProvince)
    if (provincia) {
      setResidenceProvinceName(provincia?.nome)
      if (firstLoad) {
        setFirstLoad(false)
      } else {
        setValue("residenceCity", "")
      }
    }
  }, [provinces.isFetched, residenceProvince])

  useEffect(() => {
    if (residenceCountry !== "it") {
      setValue("residenceProvince", "")
    }
  }, [residenceCountry])

  useEffect(() => {
    console.log("onChanged " + residenceCountry + " " + residenceProvince + " " + residenceAddress+" "+residenceCity + " "+ isValid+" "+isDirty)
    onChanged(getValues(), isValid, isDirty)
  }, [residenceCountry, residenceProvince, residenceCity, residenceAddress, residenceCAP, isValid])

  console.log("isDirty="+isDirty)


  return (

    <ColumnCenter>

      <h3>{t("profile.title.residence")}</h3>

      <FormContainer>

        <AutoCompleteField<CountryModel>
          name={"residenceCountry"}
          label={t("profile.label.residenceCountry")}
          optionValue={"alpha2"}
          optionLabel={"name"}
          initialValue={profile.residenceCountry || ""}
          register={register}
          control={control}
          error={!!errors["residenceCountry"]}
          uppercase={true}
          placeholder={t("profile.placeholder.residenceCountry")}
          autocompleteProps={{
            options: countries.data || [],
            renderOption: FlagRenderer
          }}/>


        { residenceCountry === "it" && (

          <AutoCompleteField<ProvinceModel>
            name={"residenceProvince"}
            label={t("profile.label.residenceProvince")}
            optionValue="sigla"
            optionLabel="nome"
            initialValue={profile.residenceProvince || ""}
            register={register}
            control={control}
            error={!!errors["residenceProvince"]}
            uppercase={true}
            placeholder={t("profile.placeholder.residenceProvince")}
            autocompleteProps={
              {options: provinces.data || []}
            }
          />
        )}

        <AutoCompleteField<CityModel>
          name={"residenceCity"}
          label={t("profile.label.residenceCity")}
          optionValue={"nome"}
          optionLabel={"nome"}
          initialValue={profile.residenceCity || ""}
          register={register}
          control={control}
          error={!!errors["residenceCity"]}
          uppercase={true}
          placeholder={t("profile.placeholder.residenceCity")}
          autocompleteProps={{
            options: residenceCountry === "it" ? comuni : [],
            freeSolo: residenceCountry !== "it" || residenceProvince === undefined
          }}/>

        <InputTextField name={"residenceAddress"}
                        label={t("profile.label.residenceAddress")}
                        error={!!errors["residenceAddress"]}
                        required
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.residenceAddress")}/>

        <NumericField name={"residenceCAP"}
                      label={t("profile.label.residenceCap")}
                      error={!!errors["residenceCAP"]}
                      required
                      register={register}
                      setValue={setValue}
                      defaultValue={profile.residenceCAP}
                      placeholder={t("profile.placeholder.cap")}/>

      </FormContainer>

    </ColumnCenter>
  )
}

export default FormResidence