import React from 'react'

import {SpaceBox, BorderBox, RowFlex} from "../Containers"
import {useTranslation} from "react-i18next"
import {TransactionModel, TransactionPartyType} from "../../models/transactionModel"
import BoxTransCode from "./BoxTransCode";
import BoxTransPin, {PinProps} from "./BoxTransPin";
import BoxTransUpload, {PropsUpload} from "./BoxTransUpload";


interface Props extends PropsUpload, PinProps {
  transaction: TransactionModel

}

const PanelTransCodes: React.FunctionComponent<Props> = ({
     transaction,
     onPinSubmit, pinPosting, pinError, pinSuccess,
     onUploadFileChanged, onUploadFileSubmit, uploading,uploadError, uploadFile
  }) => {

  const { t } = useTranslation()


  let boxes = []
  const selling = transaction.partyType === TransactionPartyType.seller

  const boxTransCode = <BoxTransCode
    key={"1"}
    header={"STEP "+ (selling ? "1" : "2")}
    title={t("transaction.complete." +(selling ? "seller" : "buyer")+ ".code.text")}
    transaction={transaction}/>
  const boxTransPin = <BoxTransPin
    key={"2"}
    header={"STEP "+ (selling ? "2" : "1")}
    title={t("transaction.complete."+(selling ? "seller" : "buyer")+".pin.text")}
    onPinSubmit={onPinSubmit}
    pinPosting={pinPosting}
    pinSuccess={pinSuccess}
    pinError={pinError}
    transaction={transaction}/>

  boxes = [boxTransCode, boxTransPin]
  if (selling) {

    const exchangeCodeUsed = transaction.counterpartInsertedEcode || false
    const counterpartCode = transaction?.counterpartECode || ""
    const counterpartCodeUsed = counterpartCode !== "" || pinSuccess
    const propertyTransferEnabled = exchangeCodeUsed && counterpartCodeUsed && !transaction.propertyTransURL

    const boxTransUpload =  <BoxTransUpload header={t("STEP 3")}
                                            key={"3"}
                                            title={t("transaction.complete.property.transfer.text")}
                                            active={propertyTransferEnabled}
                                            onUploadFileChanged={onUploadFileChanged}
                                            onUploadFileSubmit={onUploadFileSubmit}
                                            uploading={uploading}
                                            uploadError={uploadError}
                                            uploadFile={uploadFile}
                                            background={"#F4F4F4"}/>

    boxes.push(boxTransUpload)
  } else {
    boxes.reverse()
  }



  return (
    <BorderBox padding={"20px"}>
      <h3>{t("transaction.header.property")}</h3>
      <SpaceBox size={16}/>
      <RowFlex style={{gap: "20px"}}>
        {boxes}
      </RowFlex>
    </BorderBox>
  )
}

export default PanelTransCodes

