import {FormEvent} from "react";

export const updatedValuesAtIndex = (index: number, value: boolean, values: boolean[]) => {
  return values.map( (item, position) => {
    if (position === index) return value
    else return item
  });
}



export const capitalize = (event: FormEvent) => {
  const { target } = event;
  if (!(target instanceof HTMLInputElement)) {
    return;
  }
  const { selectionStart, selectionEnd } = target
  Object.assign(target, { value: target.value.toUpperCase() })
  target.setSelectionRange(selectionStart, selectionEnd);
}

export const cleanupData = (data: any, keysToKeep: string[]) => {
  return  Object.fromEntries(
    Object.entries(data).filter(
      ([key, val]) => keysToKeep.includes(key)
    )
  )
}


export const decodeCurrency = (currency: string) => {
  switch (currency) {
    case "EUR": return "€";
    default: return currency;
  }
}