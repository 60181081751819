import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Column, BorderBox, MarginBox} from "../Containers";
import {useForm} from "react-hook-form";
import {getDocumentOptions, ProfileDataBirthDocument} from "../../models/profile.models";
import {CityModel, CountryModel, ProvinceModel} from "../../models/geo.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import CalendarField from "../fields/CalendarField";
import useGeo from "../../hooks/useGeo";
import {FlagRenderer, FormContainer} from "../fields/Common";
import {Box} from "@mui/material";
import InputTextField from "../fields/InputTextField";
import FileField from "../fields/FileField";


interface Props {
  profile: ProfileDataBirthDocument
  onChanged: (profile: ProfileDataBirthDocument, valid: boolean) => void
}

const FormBirthDocument: FunctionComponent<Props> = ({profile, onChanged}) => {

  const {t} = useTranslation()
  const {register, watch,
    formState: {errors, isValid},
    control, getValues, setValue
  } = useForm({defaultValues: profile, mode: "all"});

  const {countries, provinces, useCities} = useGeo();
  const [birthProvinceName, setBirthProvinceName] = useState<string | undefined>()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const birthCountry = watch("birthCountry")
  const birthProvince = watch("birthProvince")
  const birthCity = watch("birthCity")
  const birthDate = watch("birthDate")

  const docType = watch("docType")
  const docNum = watch("docNum")
  const docExpiryDate = watch("docExpiryDate")
  const docPictureFrontFile = watch("docPictureFrontFile")
  const docPictureBackFile = watch("docPictureBackFile")

  const cities = useCities(birthProvinceName)
  const comuni = cities.isFetched ? cities.data || [] : []

  useEffect(() => {
    const provincia = provinces.data?.find(item => item.sigla === birthProvince)
    if (provincia) {
      setBirthProvinceName(provincia?.nome)
      if (firstLoad) {
        setFirstLoad(false)
      } else {
        setValue("birthCity", "")
      }
    }
  }, [provinces.isFetched, birthProvince])

  useEffect(() => {
    if (birthCountry !== "it") {
      setValue("birthProvince", "")
    }
  }, [birthCountry])

  useEffect(() => {
    onChanged(getValues(), isValid)
  }, [birthCountry, birthProvince, birthCity, birthDate, docType, docNum, docExpiryDate, docPictureFrontFile, docPictureBackFile, isValid])

  return (

    <Column>
      <FormContainer>
        <BorderBox padding={"30px 0"}>
          <MarginBox left={"40px"} bottom={"20px"}><h3>{t("profile.title.birth")}</h3></MarginBox>
          <Box mb={"20px"}>


              <AutoCompleteField<CountryModel> name={"birthCountry"}
                                               padding={"0 10px 0 40px"}
                                               width={"50%"}
                                               label={t("profile.label.birthCountry")}
                                               optionValue={"alpha2"}
                                               optionLabel={"name"}
                                               initialValue={profile.birthCountry || ""}
                                               register={register}
                                               control={control}
                                               error={!!errors["birthCountry"]}
                                               uppercase={true}
                                               placeholder={t("profile.placeholder.birthCountry")}
                                               autocompleteProps={{
                                                 options: countries.data || [],
                                                 autoHighlight: true,
                                                 noOptionsText: t("select.miss.text"),
                                                 renderOption: FlagRenderer
                                               }}/>

            { birthCountry === "it" &&
              <AutoCompleteField<ProvinceModel> name={"birthProvince"}
                                                width={"50%"}
                                                padding={"0 10px 0 0"}
                                                label={t("profile.label.birthProvince")}
                                                optionValue="sigla"
                                                optionLabel="nome"
                                                initialValue={profile.birthProvince || ""}
                                                register={register}
                                                control={control}
                                                error={!!errors["birthProvince"]}
                                                uppercase={true}
                                                placeholder={t("profile.placeholder.birthProvince")}
                                                autocompleteProps={{
                                                  options: provinces.data || [],
                                                  autoHighlight: true,
                                                  noOptionsText: t("select.miss.text")
                                                }}/>}

          </Box>
          <Box>


              <AutoCompleteField<CityModel> name={"birthCity"}
                                            width={"50%"}
                                            padding={"0 10px 0 40px"}
                                            label={t("profile.label.birthCity")}
                                            optionValue={"nome"}
                                            optionLabel={"nome"}
                                            initialValue={profile.birthCity || ""}
                                            register={register}
                                            control={control}
                                            setter={getValues}
                                            error={!!errors["birthCity"]}
                                            uppercase={true}
                                            placeholder={t("profile.placeholder.birthCity")}
                                            autocompleteProps={{
                                              options: birthCountry === "it" ? comuni : [],
                                              autoHighlight: true,
                                              noOptionsText: t("select.miss.text"),
                                              freeSolo: birthCountry !== "it"
                                            }}/>

            <CalendarField name={"birthDate"}
                           width={"50%"}
                           padding={"0 10px 0 0"}
                           label={t("profile.label.birthDate")}
                           getValues={getValues}
                           setValue={setValue}
                           register={register}
                           required
                           error={!!errors["birthDate"]}
                           placeholder={t("profile.placeholder.birthDate")}
                           defaultValue={profile.birthDate || ""}
                           disableFuture={true}/>


          </Box>
        </BorderBox>

        <BorderBox padding={"30px 0"}>
          <MarginBox left={"40px"} bottom={"20px"}><h3>{t("profile.title.document")}</h3></MarginBox>

          <Box mb={"20px"}>
            <AutoCompleteField<string> name={"docType"}
                                       width={"50%"}
                                       padding={"0 10px 0 40px"}
                                       label={t("profile.label.docType")}
                                       optionValue={"value"}
                                       optionLabel={"label"}
                                       initialValue={profile.docType}
                                       register={register}
                                       control={control}
                                       error={!!errors["docType"]}
                                       uppercase={true}
                                       placeholder={t("profile.placeholder.docType")}
                                       autocompleteProps={{
                                         options: getDocumentOptions(t),
                                         noOptionsText: t("select.miss.text"),
                                       }}/>


            <InputTextField name={"docNum"}
                            width={"50%"}
                            padding={"0 10px 0 0"}
                            label={t("profile.label.docNum")}
                            required
                            register={register}
                            error={!!errors["docNum"]}
                            uppercase={true}
                            placeholder={t("profile.placeholder.docNum")}/>
          </Box>
          <Box>
            <CalendarField name={"docExpiryDate"}
                           width={"33%"}
                           padding={"0 10px 0 40px"}
                           label={t("profile.label.docExpiryDate")}
                           placeholder={t("profile.placeholder.docExpiryDate")}
                           required
                           getValues={getValues}
                           setValue={setValue}
                           register={register}
                           error={!!errors["docExpiryDate"]}
                           defaultValue={profile.docExpiryDate || ""}
                           disablePast={profile.docExpiryDate === ""}/>


            <FileField name={"docPictureFrontFile"}
                       width={"33%"}
                       padding={"0 10px 0 0"}
                       link={profile.docPictureFrontLink || ""}
                       label={t("profile.label.docPictureFront")}
                       required
                       getValues={getValues}
                       setValue={setValue}
                       register={register}
                       error={!!errors["docPictureFrontFile"]}
                       placeholder={t("profile.placeholder.docPictureFront")}/>


            <FileField name={"docPictureBackFile"}
                       width={"33%"}
                       padding={"0 10px 0 0"}
                       link={profile.docPictureBackLink || ""}
                       label={t("profile.label.docPictureBack")}
                       required
                       getValues={getValues}
                       setValue={setValue}
                       register={register}
                       error={!!errors["docPictureBackFile"]}
                       placeholder={t("profile.placeholder.docPictureBack")}/>

          </Box>
        </BorderBox>
      </FormContainer>
    </Column>
  )

}

export default FormBirthDocument