import {BorderBox, BoxSection, RowFlex, SpaceBox} from "../Containers";
import BoxTransState from "./BoxTransState";
import {
  isBuyerTransaction,
  isSellerTransaction,
  TransactionModel,
  TransactionTypes
} from "../../models/transactionModel";
import BoxTransUser from "./BoxTransUser";
import {Box} from "@mui/material";
import LabelValue from "./LabelValue";
import {decodeCurrency} from "../../utils/Functions";
import React from "react";
import {useTranslation} from "react-i18next";
import {UseQueryResult} from "@tanstack/react-query";
import {ProfileModel} from "../../models/profile.models";

const decodeType = (transType: string) => {
  return TransactionTypes.filter( (item) => item.id === transType)[0]?.i18n
}


interface Props {
  transaction: TransactionModel,
  buyerTransactionId?: string
  sellerTransactionId?: string
  buyer: UseQueryResult<ProfileModel, string>
  seller: UseQueryResult<ProfileModel, string>
}
const PanelTransInfo: React.FunctionComponent<Props> = ({transaction, buyer, seller, buyerTransactionId, sellerTransactionId}) => {

  const { t } = useTranslation()

  return (
    <BorderBox padding={"20px"}>
      <BoxTransState state={transaction.state} partyType={transaction.partyType}/>
      <SpaceBox size={12}/>
      <RowFlex>
        <BoxSection>
          <h3>{t("transaction.partyType.buyer")} {isBuyerTransaction(transaction) && "*"}</h3>
          <BoxTransUser label={t("profile.text.name")}
                        profile={buyer.data}
                        identifier={buyerTransactionId}
                        isSuccess={buyer.isFetched}
                        isPending={buyer.isFetching}
                        error={buyer.error}/>
        </BoxSection>
        <BoxSection>
          <h3>{t("transaction.partyType.seller")} {isSellerTransaction(transaction) && "*"}</h3>
          <RowFlex>
            <BoxTransUser label={t("profile.text.name")}
                          profile={seller.data}
                          identifier={sellerTransactionId}
                          isSuccess={seller.isFetched}
                          isPending={seller.isFetching}
                          error={seller.error}/>
          </RowFlex>
        </BoxSection>
        <BoxSection>
          <h3>{t("transaction.header.view")}</h3>
          <RowFlex>
            <Box>
              <LabelValue label={t("transaction.label.subject")} value={t(decodeType(transaction.itemType))}/>
              <LabelValue label={t("transaction.label.currency")} value={decodeCurrency(transaction.currency)}/>
            </Box>
            <SpaceBox size={16}/>
            <Box>
              <LabelValue label={t("transaction.label.plate")} value={t(transaction.licensePlates)}/>
              <LabelValue label={t("transaction.label.price.long")} value={transaction.price.toLocaleString()}/>
            </Box>
          </RowFlex>
        </BoxSection>
      </RowFlex>
    </BorderBox>

  )
}

export default PanelTransInfo