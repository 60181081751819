import http from "./http"

const BASE_URL = process.env.REACT_APP_FEES_API_ENDPOINT


const getFee = (price: number) =>  {
  return http.get("/buyer/sctAmount?price="+price, {baseURL: BASE_URL})
}


const FeeService = {
  getFee
}

export default FeeService