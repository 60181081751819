import React from "react";
import {Checkbox, FormControl, FormControlLabel, MenuItem, Select} from "@mui/material";
import styled from "@emotion/styled";

export interface Props<T> {
  label: string
  value: T | ""

  options: T[]
  filter: T | null
  optionValue: string

  emptyText?: string
  getOptionLabel: (option: T) => string
  onSelectionChanged: (value: T | null) => void
}

const CheckSelect = <T,>(props: Props<T>) => {

  const { label, value, options, optionValue, filter, emptyText = "", getOptionLabel, onSelectionChanged} = props

  const [checked, setChecked] = React.useState(value !== "")

  const selectables = options.filter( (option) => option !== filter)

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked)
    onSelectionChanged(checked ? value !== "" ? value : null : null)
  };
  const handleSelect = (option: T) => {
    onSelectionChanged(option)
  };

  const items = selectables.map( (option, index) => {
    const item = option as any
    return  <MenuItem key={label+"-"+item[optionValue]} value={item}>{getOptionLabel(item)}</MenuItem>
  });

  return (
    <GroupSelect>

      <LabelSelect label={label}
                   id="label-{label}"
                   control={<Checkbox checked={checked} onChange={handleCheck}/>}/>

      <BorderedSelect disabled={!checked}
                      value={value}
                      variant="outlined"
                      labelId="label-{label}"
                      id="select-{label}"
                      displayEmpty
                      renderValue={(item) => item ? getOptionLabel(item as any) : emptyText}
                      onChange={(event) => { handleSelect(event.target.value as T)}}>
        {items}
      </BorderedSelect>
    </GroupSelect>
  )

}
export default CheckSelect

const GroupSelect = styled(FormControl)`
  flex-direction: row;
, align-content: center;
`


const LabelSelect = styled(FormControlLabel)`
  width: 180px;

  @media screen and (max-width: 480px) {
    width: 150px;
  }
`

const BorderedSelect = styled(Select)`
  width: 200px;
  border-radius: 6px;
  border: 1px solid var(--color-text-dark);

  @media screen and (max-width: 480px) {
    width: 160px;
  }
`
