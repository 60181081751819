import {useQuery} from "@tanstack/react-query";
import ProfileService from "../services/ProfileService";
import {CityModel, CountryModel, ProvinceModel} from "../models/geo.models";


const getCountries = async (): Promise<CountryModel[]> => {
  const { data } = await ProfileService.getCountries()
  return data;
};

const getITProvinces = async (): Promise<ProvinceModel[]> => {
  const { data } = await ProfileService.getITProvinces()
  return data;
};

const getITComuni = async (province: string): Promise<CityModel[]> => {
  const { data } = await ProfileService.getITComuni(province)
  return data;
};


const useGeo = () => {

  const useQueryCountries = useQuery<CountryModel[], string>({
    queryKey: ["countries"],
    queryFn: getCountries,
    staleTime: Infinity
  });

  const useQueryITProvinces = useQuery<ProvinceModel[], string>({
    queryKey: ["provinces"],
    queryFn: getITProvinces,
    staleTime: Infinity
  });

  const useQueryITComuni = (province?: string)  => {
    return useQuery<CityModel[], string>({
      queryKey: ["comuni", province],
      enabled: province !== undefined,
      queryFn: () => getITComuni(province!),
      staleTime: Infinity
    });
  }

  return {
    countries: useQueryCountries,
    provinces: useQueryITProvinces,
    useCities: useQueryITComuni
  }
}
export default useGeo;

