import http from "./http";

const BASE_URL = process.env.REACT_APP_OPERATOR_API_ENDPOINT;


const getKey = () => {
  return http.get("/key", {baseURL: BASE_URL});
};

const OperatorService = {
  getKey
}

export default OperatorService;