import http from "./http"
import {ProfileModel} from "../models/profile.models"
import Auth from "./Auth"

const BASE_URL = process.env.REACT_APP_PROFILE_API_ENDPOINT

const listProfiles = () => {
  return http.get("/profiles", {baseURL: BASE_URL})
}

const listOperatorProfiles = () => {
  return http.get(`/operators/${Auth.getOperatorKey()}/profiles`, {baseURL: BASE_URL})
}

const getOperatorBasicProfileByUserId = (userId: string) => {
  return http.get(`/operators/profiles/basic/${userId}`, {baseURL: BASE_URL})
}

const getOperatorProfileById = (profileId: string) => {
  return http.get(`/operators/${Auth.getOperatorKey()}/profiles/${profileId}`, {baseURL: BASE_URL})
}

const getProfileById = (profileId: string)=> {
  return http.get(`/profiles/${profileId}`, {baseURL: BASE_URL})
}

const getProfileByUserId = (userId: string) => {
  return http.get(`/users/${userId}/profile`, {baseURL: BASE_URL})
}

const updateProfile = (profile: ProfileModel) => {
  const key = Auth.getOperatorKey()
  if (key) {
    profile.operatorKey = key
  }
  return http.put(`/profile`, profile, {baseURL: BASE_URL})
}

const getProfileData = () => {
  return http.get("/profile/profile", {baseURL: BASE_URL})
}

const getCountries = () => {
  return http.get("/countries", {baseURL: BASE_URL})
}

const getITProvinces = () => {
  return http.get("/provinces/it", {baseURL: BASE_URL})
}

const getITComuni = (provincia: string) => {
  return http.get(`/comuni?provincia=${provincia}`, {baseURL: BASE_URL})
}


const ProfileService = {
  listProfiles,
  getProfileById,
  getProfileByUserId,
  listOperatorProfiles,
  getOperatorProfileById,
  getOperatorBasicProfileByUserId,
  updateProfile,
  getProfileData,
  getCountries,
  getITProvinces,
  getITComuni
}

export default ProfileService
