import {useQuery} from "@tanstack/react-query";
import {FeeModel} from "../models/user.models";
import FeeService from "../services/FeeService";


const getFee = async (price: number): Promise<FeeModel> => {
  const { data } = await FeeService.getFee(price)
  return data;
};

const useFee = (price?: number) => {

  const useQueryFee = useQuery<FeeModel, string>({
    queryKey: ["fee", price],
    queryFn: () => getFee(price!),
    enabled: price !== undefined && price > 0,
    staleTime: Infinity
  });



  return {
    fee: useQueryFee
  }
}
export default useFee;

