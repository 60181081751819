import {useQuery} from "@tanstack/react-query";
import {TransactionModel} from "../models/transactionModel";
import TransactionService from "../services/TransactionService";
import {QueryMode} from "../models/constants.enum";


const listTransactions = async (): Promise<TransactionModel[]> => {
  const { data } = await TransactionService.list()
  return data.transactions;
}

const listOperatorTransactions = async (): Promise<TransactionModel[]> => {
  const { data } = await TransactionService.listOperator()
  return data.transactions;
};


const useListTransactions = (mode: QueryMode = QueryMode.USERID) => {

  return useQuery<TransactionModel[], string>({
    queryKey: ["transactions", mode],
    queryFn: () => {
      switch (mode) {
        case QueryMode.USERID:
          return listTransactions()
        case QueryMode.OPERATOR:
          return listOperatorTransactions()
        default:
          return Promise.resolve([] as TransactionModel[])
      }
    },
  });
}
export default useListTransactions;

