import React from "react";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import {HorizontalBox} from "../Containers";
import {ErrorIndicator, FormField, InputText, TextLabel} from "./Common";

interface Props extends StandardTextFieldProps {
  width?: string
  padding?: string
}

const ComputedField: React.FC<Props> = (props) => {

  const {
    label, width = "320px", padding = "0",
    ...textFieldProps
  } = props;



  const inputProps = { readOnly: true}

  return (
    <FormField width={width} sx={{padding: padding}}>
      <TextLabel position={"start"}>{label}</TextLabel>
      <HorizontalBox>
        <InputText variant={"outlined"} sx={{width: "100%"}}
                   {...textFieldProps}
                   InputProps={inputProps}
        />
        <ErrorIndicator error={false}/>
      </HorizontalBox>
    </FormField>
  )
}

export default ComputedField
