import React from "react";
import {TextField} from "@mui/material";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import {HorizontalBox} from "../Containers";
import {NumericFormat} from "react-number-format";
import {ErrorIndicator, FormField, InputText, TextLabel} from "./Common";
import {UseFormRegister, UseFormSetValue} from "react-hook-form";


interface Props extends StandardTextFieldProps {
  format?: "text" | "numeric" | "currency"
  currency?: string
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  width?: string
  padding?: string
}

const NumericField: React.FC<Props> = (props) => {

  const { name = "", required, error, defaultValue = "",
    register, setValue,
    currency, label,
    width = "320px", padding = "0",

  } = props;


  const currencyProps = currency ? {thousandSeparator: true, prefix: currency+" "} : { width: "100%"}

  const handleChange = (value: string) => {
    setValue(name, value, { shouldValidate: true, shouldDirty: value !== defaultValue })
  }

  return (
    <FormField width={width} sx={{padding: padding}}>

      <TextField sx={{display: "none"}}
                 {...register(name, {required: required}) }/>

      <TextLabel position={"start"}>{label}</TextLabel>

      <HorizontalBox>
        <NumericFormat customInput={InputText}
                       placeholder={props.placeholder}
                       error={error}
                       value={defaultValue as string}
                       onValueChange={(values) => handleChange(values.value)}
                       allowNegative={false}
                       style={{width:"100%"}}
                       {...currencyProps}/>
        <ErrorIndicator error={error}/>
      </HorizontalBox>
    </FormField>
  )
}

export default NumericField
