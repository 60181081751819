import React from "react";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import styled from "@emotion/styled";
import {HorizontalBox} from "../Containers";
import {ErrorIndicator, FormField, FormProps, OptionsProps, TextLabel} from "./Common";

interface Props extends FormProps, OptionsProps {}

const RadioField: React.FC<Props> = (props) => {

  const {name = "", label, defaultValue = "",
    options, optionValue, optionLabel,
    required, error,
    width = "320px", padding = "0", minWidth = "320px",
    register, setValue, getValues
  } = props;



  const items = options.map( (option) => {
    const item = option as any
    const checked = item[optionValue] === getValues(name)
    return <FormControlLabel key={item[optionValue]}
                             value={item[optionValue]}
                             label={item[optionLabel]}
                             control={<Radio checked={checked}/>}/>
  });

  const handleChange = (value: any) => {
    setValue(name, value, { shouldValidate: true, shouldDirty: value !== defaultValue })
  }

  return (

    <FormField width={width} sx={{padding: padding, minWidth: minWidth}}>
      <TextLabel position={"start"}>{label}</TextLabel>
      <HorizontalBox>

        <TextField sx={{display: "none"}}
                   {...register(name, {required: required}) }/>

        <BorderedRadioGroup row
                    name={name}
                    aria-labelledby={"radio-"+name}
                    onChange={(event) => { handleChange(event.target.value) }}>
          {items}
        </BorderedRadioGroup>
        <ErrorIndicator error={error}/>
      </HorizontalBox>


    </FormField>
  )

}
export default RadioField

const BorderedRadioGroup = styled(RadioGroup)`
  border-radius: 6px;
  border: 1px solid  rgba(0, 0, 0, 0.23);
  padding: 0 14px;
  width: 100%;

  :hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  /*
   label span  {
     font-size: 18px;
  }*/
`
