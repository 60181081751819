import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {useForm} from "react-hook-form";
import {ProfileDataBirth} from "../../models/profile.models";
import {CityModel, CountryModel, ProvinceModel} from "../../models/geo.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import CalendarField from "../fields/CalendarField";
import useGeo from "../../hooks/useGeo";
import {FlagRenderer, FormContainer} from "../fields/Common";


interface Props {
  profile: ProfileDataBirth
  onChanged: (profile: ProfileDataBirth, valid: boolean, dirty: boolean) => void
}

const FormBirth: FunctionComponent<Props> = ({profile, onChanged}) => {

  const {t} = useTranslation()
  const {register, watch,
    formState: {errors, isValid, isDirty},
    control, getValues, setValue
  } = useForm({defaultValues: profile, mode: "all"});

  const { countries, provinces, useCities} = useGeo();
  const [birthProvinceName, setBirthProvinceName] = useState<string | undefined>()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const birthCountry = watch("birthCountry")
  const birthProvince = watch("birthProvince")
  const birthCity = watch("birthCity")
  const birthDate = watch("birthDate")

  const cities = useCities(birthProvinceName)
  const comuni = cities.isFetched ? cities.data || [] : []

  useEffect(() => {
    const provincia = provinces.data?.find(item => item.sigla === birthProvince)
    //console.log("setBirthProvinceName " +birthProvince+" -> "+ provincia?.nome + " " + firstLoad)
    if (provincia) {
      setBirthProvinceName(provincia?.nome)
      if (firstLoad) {
        setFirstLoad(false)
      } else {
        setValue("birthCity", "")
      }
    }
  }, [provinces.isFetched, birthProvince])

  useEffect(() => {
    if (birthCountry !== "it") {
      setValue("birthProvince", "")
    }
  }, [birthCountry])

  useEffect(() => {
    console.log("onChanged " + birthCountry + " " + birthProvince + " " + birthCity + " " + birthDate+ " "+ isValid+" "+isDirty)
    onChanged(getValues(), isValid, isDirty)
  }, [birthCountry, birthProvince, birthCity, birthDate, isValid])

  console.log("isDirty="+isDirty)

  return (

    <ColumnCenter>

      <h3>{t("profile.title.birth")}</h3>

      <FormContainer>

        <AutoCompleteField<CountryModel>
          name={"birthCountry"}
          label={t("profile.label.birthCountry")}
          optionValue={"alpha2"}
          optionLabel={"name"}
          initialValue={profile.birthCountry || ""}
          register={register}
          control={control}
          error={!!errors["birthCountry"]}
          placeholder={t("profile.placeholder.birthCountry")}
          uppercase={true}
          autocompleteProps={{
            options: countries.data || [],
            renderOption: FlagRenderer
          }}/>


        { birthCountry === "it" && (
          <AutoCompleteField<ProvinceModel>
            name={"birthProvince"}
            label={t("profile.label.birthProvince")}
            optionValue="sigla"
            optionLabel="nome"
            initialValue={profile.birthProvince || ""}
            register={register}
            control={control}
            error={!!errors["birthProvince"]}
            uppercase={true}
            placeholder={t("profile.placeholder.birthProvince")}
            autocompleteProps={{
              options: provinces.data || []
            }}/>

        )}

        <AutoCompleteField<CityModel>
          name={"birthCity"}
          label={t("profile.label.birthCity")}
          optionValue={"nome"}
          optionLabel={"nome"}
          initialValue={profile.birthCity || ""}
          register={register}
          control={control}
          setter={getValues}
          error={!!errors["birthCity"]}
          uppercase={true}
          placeholder={t("profile.placeholder.birthCity")}
          autocompleteProps={{
            options: birthCountry === "it" ? comuni : [],
            freeSolo: birthCountry !== "it"
          }}/>

        <CalendarField
          name={"birthDate"}
          label={t("profile.label.birthDate")}
          getValues={getValues}
          setValue={setValue}
          register={register}
          required
          error={!!errors["birthDate"]}
          placeholder={t("profile.placeholder.birthDate")}
          defaultValue={profile.birthDate || ""}
          disableFuture={true}/>


      </FormContainer>

    </ColumnCenter>
  )

}

export default FormBirth