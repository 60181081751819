import {TransactionModel, TransactionPartyType} from "../models/transactionModel"
import {useEffect, useState} from "react"
import useGetProfile from "./useGetProfile"
import {QueryMode} from "../models/constants.enum"
import useGetTransaction from "./useGetTransaction"
import useListProfiles from "./useListProfiles";

const useViewTransaction = (transactionId: string) => {


  const [buyerId, setBuyerId] = useState<string | undefined>()
  const [sellerId, setSellerId] = useState<string | undefined>()
  const [matchedTransactionId, setMatchedTransactionId] = useState<string | undefined>()

  const transaction = useGetTransaction(transactionId)
  const profiles = useListProfiles(QueryMode.OPERATOR)

  const buyer = useGetProfile(QueryMode.BASIC, buyerId)
  const seller = useGetProfile(QueryMode.BASIC, sellerId)
  const matchedTransaction = useGetTransaction(matchedTransactionId)

  // operatorManaged e' true se buyer e seller appartengono all'operatore
  // l'assegnazione true/false avviene solo dopo aver caricato tutti i dati e puo' essere dal consumer come kickoff
  const [operatorManaged, setOperatorManaged] = useState<boolean | undefined>()

  const decodePartyType = (trans: TransactionModel) => {
    if (trans.partyType === TransactionPartyType.seller) {
      setSellerId(trans.user)
    } else {
      setBuyerId(trans.user)
    }
  }

  // eseguito dopo aver caricato la transazione corrente,
  // triggera il caricamento della transazione matchata
  useEffect(() => {
    const trans = transaction.data
    if (trans) {
      decodePartyType(trans)
      setMatchedTransactionId(trans.match)
    }
  }, [transaction.data]);


  // eseguito dopo aver caricato la transazione matchata
  useEffect(() => {
    const trans =  matchedTransaction.data
    if (trans) {
      decodePartyType(trans)
    }
  }, [matchedTransaction.data]);

  // eseguito dopo aver caricato i profili e dopo aver identificato buyer e seller
  useEffect(() => {
    if (profiles.data && buyerId && sellerId) {
      const operated = profiles.data.filter( (item) => item.userId === buyerId).length > 0
      && profiles.data.filter( (item) => item.userId === sellerId).length > 0
      setOperatorManaged(operated)
    }
  }, [profiles.data, buyerId, sellerId]);


  return {
    operatorManaged: operatorManaged,
    transaction: transaction,
    matchedTransaction: matchedTransaction,
    seller: seller,
    buyer: buyer
  }
}
export default useViewTransaction;

