export enum UrlsEnum  {
  ROOT = "/",
  LOGIN = "/login",
  INITIAL = "/transactions",
  TRANSACTIONS = "/transactions",
  TRANSACTION_CREATE = "/transactions/create",
  PROFILES = "/profiles",
  PROFILES_CREATE = "/profiles/create",

  PRIVACY = "http://www.pleggit.com/privacy",
  TERMS = "https://www.pleggit.com/terms"
}
