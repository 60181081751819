import React from "react";
import {Autocomplete} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete";
import {ErrorIndicator, FormField, InputText, TextLabel} from "./Common";
import {HorizontalBox} from "../Containers";
import {Controller, UseFormSetValue} from "react-hook-form";
import {capitalize} from "../../utils/Functions";

export interface AutoCompleteFieldProps<T extends Object> {
  autocompleteProps: Omit<AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>, "renderInput">
  name: string,
  placeholder: string,
  optionValue: string,
  optionLabel: string,
  initialValue?: string,
  loading?: boolean,
  register: any,
  label: string
  error?: boolean
  control: any
  setter?: UseFormSetValue<any>
  width?: string
  padding?: string
  uppercase?: boolean
}

const AutoCompleteField = <T,>(props: AutoCompleteFieldProps<any>) => {

  const {
    name, label, error, control, placeholder,
    optionValue, optionLabel, autocompleteProps,
    initialValue,
    width = "320px", padding = "0",
    uppercase = false
  } = props;
  const options = autocompleteProps.options || []

  const decodeLabel = (option: any) => {
    let label
    if (typeof option === 'string') {
      const value = option
      option = options.find((option) => option[optionValue] === value) as any
      label = (option && option[optionLabel]) || ""
    } else {
      label = option[optionLabel]
    }
    if (uppercase && typeof label === 'string') {
      label = label.toUpperCase()
    }
    return label
  }

  const handleChange = (option: any, onChangeFn: (event: any) => void ) => {
    onChangeFn(option !== null ? option[optionValue] : null)
  }

  const decodeValue = (value: any) => {
    if (autocompleteProps.freeSolo) {
      return null
    } else {
      return value !== "" ? value : null
    }
  }

  const isEqual = (option: any, value: string): boolean => {
    if (autocompleteProps.freeSolo) {
      return true
    } else if (typeof value === "object") {
      return option[optionValue] ===  value[optionValue]
    } else {
      return option[optionValue] === value
    }
  }




  return (

    <Controller
      control={control}
      name={name}
      rules={{ required: true, minLength:1 }}
      defaultValue={initialValue}
      render={({field: { ref, onChange, value } }) =>{
       // console.log("init "+initialValue+" "+value+" -> "+ decodeValue(value))
        return (
          <FormField width={width} sx={{padding: padding}}>
          <TextLabel position={"start"}>{label}</TextLabel>

          <Autocomplete className={"input-no-pad"}
            value={decodeValue(value)}
            {...autocompleteProps}
            getOptionLabel={(option) => decodeLabel(option)}
            onChange={(event, option) => { handleChange(option, onChange) }}
            isOptionEqualToValue={(option, value) => { return isEqual(option, value)}}
            renderInput={(params) => {

              if (autocompleteProps.freeSolo) {
                params.inputProps.value = value
              }


              if (uppercase) {
                (params as TextFieldProps).onInput = capitalize
              }
              return(

                <HorizontalBox>
                  <InputText {...params as TextFieldProps} sx={{width: "100%"}}
                             placeholder={placeholder}
                             error={error}
                             ref={ref}
                             required/>
                  <ErrorIndicator error={error}/>

                </HorizontalBox>
              )}
            }
            onInputChange={(event, value) => {
              if (autocompleteProps.freeSolo) {
                onChange(value)
              }
            }}

          />
        </FormField>
       )}}/>
  )
}

export default AutoCompleteField


