import * as React from 'react';
import {ToolbarProps} from '@mui/material/Toolbar';
import IconSearch from '@mui/icons-material/Search';
import {InputAdornment, useMediaQuery} from "@mui/material";
import {HorizontalBox, ToolbarWide} from "../lib/components/Containers";
import {InputText} from "../lib/components/fields/Common";
import {capitalize} from "../lib/utils/Functions";


interface Props extends ToolbarProps {
  icon?: React.ReactNode
  justify?: string,
  title: string
  searchEnabled?: boolean
  searchPlaceholder?: string
  onSearchTextChanged?: (text: string) => void
}

const TopBar:  React.FunctionComponent<Props> =
  ({
     icon, title,
     searchEnabled= false, searchPlaceholder, onSearchTextChanged,
     ...props
  }) => {

    const portrait = useMediaQuery("(max-width:720px)")
    const columnMode = portrait && props.children
    const smallSearcher = portrait && !columnMode

  return (
    <ToolbarWide {...props} sx={{flexDirection: columnMode ? "column": "row", rowGap:"10px", alignItems: columnMode ? "start" : "center", padding: "10px 0 !important"}}>
      <HorizontalBox>
        {icon}
        <h2>{title}</h2>
      </HorizontalBox>

      <HorizontalBox gap={"10px"}>

        { props.children }

        {searchEnabled && onSearchTextChanged &&
          <InputText
            variant={"outlined"}
            sx={{ width : smallSearcher ? "135px" : "160px"}}
            placeholder={searchPlaceholder}
            onChange={(event) => onSearchTextChanged(event.target.value) }
            onInput={capitalize}
            InputProps={{
              style: { textTransform: "uppercase"},
              startAdornment:  <InputAdornment position="start"><IconSearch /></InputAdornment>
            }}/>
        }
      </HorizontalBox>
    </ToolbarWide>
  )}

export default TopBar


