import http from "./http"
import Auth from "./Auth"

const BASE_URL = process.env.REACT_APP_FS_API_ENDPOINT


const uploadPropertyDocument = ( transactionId: string, docTypeId: string, file: File, userId: string) =>  {
  const data = new FormData()
  data.append("file", file)
  data.append("userId", userId)

  const key = Auth.getOperatorKey()
  if (key) {
    data.append("operatorKey", key)
  }

  return http.post(`/files/transactions/${transactionId}/${docTypeId}`, data, {baseURL: BASE_URL})
}



const uploadIDPhoto = (type: string, file: File, userId?: string) => {
  const data = new FormData()
  data.append("file", file)
  if (userId) {
    data.append("userId", userId)
  }
  const key = Auth.getOperatorKey()
  if (key) {
    data.append("operatorKey", key)
  }
  return http.post(`/files/id/${type}`, data, {baseURL: BASE_URL})
  /*
  { "uploaded": true, "filepath": "112308063819057898135/front" }
   */
}

const downloadIDPhoto = (type: string) => {
  return http.get(`/files/id/${type}`, {baseURL: BASE_URL})
}

const FileService = {
  uploadPropertyDocument,
  uploadIDPhoto,
  downloadIDPhoto
}

export default FileService