import {useQuery} from "@tanstack/react-query";
import CompanyService from "../services/CompanyService";
import {CompanyInfoModel} from "../models/user.models";


const getInfo = async (): Promise<CompanyInfoModel> => {
  const { data } = await CompanyService.getInfo()
  return data;
};

const useCompany = (enabled?: boolean) => {

  const useQueryInfo = useQuery<CompanyInfoModel, string>({
    queryKey: ["info"],
    queryFn: getInfo,
    enabled: enabled === true,
    staleTime: Infinity
  });



  return {
    info: useQueryInfo
  }
}
export default useCompany;

