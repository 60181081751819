import http from "./http"
import {TransCreateModel} from "../models/transactionModel"
import Auth from "./Auth";

const BASE_URL = process.env.REACT_APP_TRAN_API_ENDPOINT


const load = (transactionId: string) => {
  return http.get(`/transactions/${transactionId}`, {baseURL: BASE_URL})
}


const list = () => {
  return http.get("/transactions", {baseURL: BASE_URL})
}

const listAdmin = () => {
  return http.get(`/admin/transactions`, {baseURL: BASE_URL})
}

const listOperator = () => {
  return http.get(`/operators/transactions`, {baseURL: BASE_URL})
}



const create = (data: TransCreateModel) => {
  return http.post("/transactions", data, {baseURL: BASE_URL})
}

const createOperator = (data: TransCreateModel) => {
  const key = Auth.getOperatorKey()
  if (key) {
    data.operatorKey = key
  }
  return http.post("/operators/transactions", data, {baseURL: BASE_URL})
}




const searchOperator = (filter?: string) => {
  return http.get(`/operators/search/transactions?operatorKey=${Auth.getOperatorKey()}&${filter ? filter : ""}`, {baseURL: BASE_URL})
}

const searchAdmin = (filter?: string) => {
  return http.get(`/admin/search/transactions?${filter ? filter : ""}`, {baseURL: BASE_URL})
}

const postPPLink = (transactionId: string, filePath: string, userId: string)  => {
  const data: any = {
    propertyProofLink: filePath,
    userId: userId,
  }
  const key = Auth.getOperatorKey()
  if (key) {
    data.operatorKey = key;
  }
  return http.post(`/transactions/${transactionId}/docs/property`, data, {baseURL: BASE_URL})
}

const postPTLink = (transactionId: string, filePath: string, userId: string)  => {
  const data: any = {
    propertyTransferLink: filePath,
    userId: userId
  }
  const key = Auth.getOperatorKey()
  if (key) {
    data.operatorKey = key;
  }
  return http.post(`/transactions/${transactionId}/docs/transfer`, data, {baseURL: BASE_URL})
}

const postExchangeCode = (transactionId: string, code: string )  => {
  return http.post(`/transactions/${transactionId}/exchangeCode`, { counterpartExchangeCode: code}, {baseURL: BASE_URL})
}

const TransactionService = {
  load,
  create,
  createOperator,
  list,
  listAdmin,
  listOperator,
  searchAdmin,
  searchOperator,
  postPPLink,
  postPTLink,
  postExchangeCode
}

export default TransactionService