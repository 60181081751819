import * as React from 'react'
import {createContext, ReactNode, useState} from 'react'
import {TokenModel} from "./models/user.models";
import Auth from "./services/Auth";



type Props = {
  children?: ReactNode
}

type IAuthContext = {
  authenticated: boolean
  token: TokenModel | null
  setAuthentication: (token: TokenModel) => void
  clearAuthentication: () => void
}

const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  token: null,
  setAuthentication: (token: TokenModel) => {},
  clearAuthentication: () =>  {}
})

const AuthProvider = (props: Props) => {
  //console.log("new AuthProvider")
  const token = Auth.getToken()
  const [authenticated, setAuthenticated ] = useState(token !== null)

  const setAuthentication = (token: TokenModel) => {
    if (token != null) {
      Auth.saveToken(token)
    } else {
      Auth.clearToken()
    }
    setAuthenticated(token != null)
  }

  const clearAuthentication = () => {
    Auth.clearToken()
    setAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{ authenticated, token, setAuthentication, clearAuthentication }}>
      {props.children}
    </AuthContext.Provider>)
}

export { AuthContext, AuthProvider }