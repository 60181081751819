import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import {TextMedium} from "../Texts";
import {MarginBox, BoxSection} from "../Containers";
import {H2Center} from "../fields/Common";

interface Props {
  header: string,
  title: string,
  padding?: string,
  background?: string
  children: any
}

const BoxTrans: React.FunctionComponent<Props> = ({header, title, padding = "20px", background = "#FFF", children}) => {

  const { t } = useTranslation();

  return (
    <BoxSection padding={padding} background={background}>
      <H2Center>{header}</H2Center>
      <MarginBox top={"10px"} height={"90px"}>
        <TextMedium $color={"#001964"}><Trans t={t}>{t(title)}</Trans></TextMedium>
      </MarginBox>
      {children}
    </BoxSection>
  )
}

export default BoxTrans;
