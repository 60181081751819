import {useQuery} from "@tanstack/react-query";
import ProfileService from "../services/ProfileService";
import {ProfileModel} from "../models/profile.models";
import {QueryMode} from "../models/constants.enum";


const getProfileById = async (profileId: string): Promise<ProfileModel> => {
  const { data } = await ProfileService.getProfileById(profileId)
  return data
};

const getProfileByUserId = async (userId: string): Promise<ProfileModel> => {
  const { data } = await ProfileService.getProfileByUserId(userId)
  return data
};


const getOperatorProfileById= async (profileId: string): Promise<ProfileModel> => {
  const { data } = await ProfileService.getOperatorProfileById(profileId)
  return data
};

const getOperatorBasicProfileByUserId = async (userId: string): Promise<ProfileModel> => {
  const { data } = await ProfileService.getOperatorBasicProfileByUserId(userId)
  return data
};



const useGetProfile = (mode: QueryMode, id?: string) => {


  return useQuery<ProfileModel, string>({
    queryKey: ["profile", id, mode],
    enabled: id !== undefined,
    queryFn: () => {
      switch (mode) {
        case QueryMode.ID:
          return getProfileById(id!)
        case QueryMode.USERID:
          return getProfileByUserId(id!)
        case QueryMode.OPERATOR:
          return getOperatorProfileById(id!)
        case QueryMode.BASIC:
          return getOperatorBasicProfileByUserId(id!)
        default:
          return Promise.resolve({} as ProfileModel)
      }
    },
    staleTime: Infinity,
  });
}
export default useGetProfile;

