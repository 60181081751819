import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {TextNormal} from "./Texts";
import {Line} from "./Containers";

const Footer: FunctionComponent = () => {

  const { t } = useTranslation();

  return (
    <>
      <Line/>
      <Container>
        <TextNormal $color={"#001964"}>{t('footer.text.copyright')}</TextNormal>
      </Container>
    </>
  )
}
export default Footer



const Container = styled.div`
  max-width: var(--max-content-width);
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-self: center;
  padding: 25px 16px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;


