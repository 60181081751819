import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import InputTextField from "../fields/InputTextField";
import {useForm} from "react-hook-form";
import {ProfileDataDocument, getDocumentOptions} from "../../models/profile.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import CalendarField from "../fields/CalendarField";
import FileField from "../fields/FileField";
import {FormContainer} from "../fields/Common";


interface Props {
  profile: ProfileDataDocument
  onChanged: (profile: ProfileDataDocument, valid: boolean, dirty: boolean) => void
}

const FormDocument: FunctionComponent<Props> = ({profile, onChanged}) => {

  const { t } = useTranslation()
  const { register, watch,
    formState: { errors, isValid, isDirty },
    control, getValues, setValue
  } = useForm({ defaultValues: profile, mode: "all" });

  const docType = watch("docType")
  const docNum = watch("docNum")
  const docExpiryDate = watch("docExpiryDate")
  const docPictureFrontFile = watch("docPictureFrontFile")
  const docPictureBackFile = watch("docPictureBackFile")

  useEffect(() => {
    onChanged(getValues(), isValid, isDirty)
  }, [docType, docNum, docExpiryDate, docPictureFrontFile, docPictureBackFile, isValid])

  return (
    <ColumnCenter>

      <h3>{t("profile.title.document")}</h3>

      <FormContainer>

        <AutoCompleteField<string> name={"docType"}
                                   label={t("profile.label.docType")}
                                   optionValue={"value"}
                                   optionLabel={"label"}
                                   initialValue={profile.docType}
                                   register={register}
                                   control={control}
                                   error={!!errors["docType"]}
                                   uppercase={true}
                                   placeholder={t("profile.placeholder.docType")}
                                   autocompleteProps={{
                                    options: getDocumentOptions(t)
                                   }}/>


        <InputTextField name={"docNum"}
                        label={t("profile.label.docNum")}
                        required
                        register={register}
                        error={!!errors["docNum"]}
                        uppercase={true}
                        placeholder={t("profile.placeholder.docNum")}/>


        <CalendarField name={"docExpiryDate"}
                       label={t("profile.label.docExpiryDate")}
                       required
                       getValues={getValues}
                       setValue={setValue}
                       register={register}
                       error={!!errors["docExpiryDate"]}
                       placeholder={t("profile.placeholder.docExpiryDate")}
                       defaultValue={profile.docExpiryDate || ""}
                       disablePast={profile.docExpiryDate === ""}/>


        <FileField name={"docPictureFrontFile"}
                   link={profile.docPictureFrontLink || ""}
                   label={t("profile.label.docPictureFront")}
                   required
                   getValues={getValues}
                   setValue={setValue}
                   register={register}
                   error={!!errors["docPictureFrontFile"]}
                   placeholder={t("profile.placeholder.docPictureFront")}/>


        <FileField name={"docPictureBackFile"}
                   link={profile.docPictureBackLink || ""}
                   label={t("profile.label.docPictureBack")}
                   required
                   getValues={getValues}
                   setValue={setValue}
                   register={register}
                   error={!!errors["docPictureBackFile"]}
                   placeholder={t("profile.placeholder.docPictureBack")}/>
      </FormContainer>

    </ColumnCenter>
  )
}

export default FormDocument