import React from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter, Filler, Warning} from "../Containers";
import Uploader from "../Uploader";
import {LoadingButtonRounded} from "../fields/Common";
import BoxTrans from "./BoxTrans";


export interface PropsUpload  {
  onUploadFileChanged: (value: File | null) => void
  onUploadFileSubmit: () => void
  uploading: boolean
  uploadError?: any
  uploadFile: File | null
}

interface Props extends PropsUpload {

  header: string
  title: string,
  padding?: string,
  background?: string
  active?: boolean
}

const BoxTransUpload: React.FunctionComponent<Props> = ({header, title, active = true, onUploadFileChanged, onUploadFileSubmit, uploading, uploadFile, uploadError}) => {

  const { t } = useTranslation();

  return (
    <BoxTrans header={header}
              title={title}
              background={"#F4F4F4"}>

      <ColumnCenter>

        <Uploader onChanged={onUploadFileChanged}/>

        <Filler/>

        <LoadingButtonRounded
          loading={uploading}
          onClick={onUploadFileSubmit}
          width={"160px"}
          variant="contained"
          disabled={!uploadFile || !active}>
          {t("button.label.confirm")}
        </LoadingButtonRounded>

        <Warning style={{visibility: uploadError ? "visible": "hidden"}} severity={"error"}>{""+uploadError}</Warning>

      </ColumnCenter>

    </BoxTrans>
  )
}


export default BoxTransUpload;

