import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {TransCreateData} from "../../models/transactionModel";
import InputTextField from "../fields/InputTextField";
import {useForm} from "react-hook-form";
import validator from "validator";
import NumericField from "../fields/NumericField";
import {FormContainer, H2Center} from "../fields/Common";
import useFee from "../../hooks/useFee";
import ComputedField from "../fields/ComputedField";

interface Props {
  data: TransCreateData,
  onChanged: (data: TransCreateData, valid: boolean) => void
  showIban: boolean
}

const FormTransData: FunctionComponent<Props> = ({data, onChanged, showIban} ) => {

  const { t } = useTranslation()
  const { register, formState: { errors, isValid },
    watch, getValues, setValue
  } = useForm({ defaultValues: data, mode:"all"})


  const licensePlates = watch("licensePlates")
  const price = watch("price")
  const iban = watch("iban")
  const currency = watch("currency")
  const {fee: paymentFee} = useFee(Number(price))


// GB33BUKB20201555555555

  useEffect(() => {
    onChanged(getValues(), isValid && paymentFee.isFetched)
  }, [licensePlates, price, iban, currency, paymentFee.isFetched, isValid])


  const feeValue = paymentFee.data?.fee ? "€ "+paymentFee.data.fee.toLocaleString() : ""


  return (

    <ColumnCenter>

      <H2Center>{t("transaction.create.step2.text")}</H2Center>

      <FormContainer>

        <InputTextField name={"licensePlates"}
                        label={t("transaction.label.plate")}
                        error={!!errors["licensePlates"]}
                        required
                        register={register}
                        placeholder={t("transaction.placeholder.plate")}/>

        <NumericField name={"price"}
                      defaultValue={data.price}
                      label={t("transaction.label.price.long")}
                      required
                      setValue={setValue}
                      format={"currency"}
                      currency={"€"}
                      error={!!errors["price"]}
                      register={register}
                      placeholder={t("transaction.placeholder.price")}/>

        {showIban &&
          <InputTextField name={"iban"}
                          label={t("transaction.label.iban")}
                          error={!!errors["iban"]}
                          required
                          validator={validator.isIBAN}
                          register={register}
                          placeholder={t("transaction.placeholder.iban")}/>}

        <ComputedField name={"fee"}
                       label={t("transaction.label.fee")}
                       value={feeValue}
                       placeholder={t(Number(price) === 0 ? "transaction.placeholder.fee.invalid" : "transaction.placeholder.fee.loading")}/>



      </FormContainer>

    </ColumnCenter>
  )

}

export default FormTransData