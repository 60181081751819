import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {UserLogin, TokenModel} from "../models/user.models";
import AuthService from "../services/AuthService";
import {useContext} from "react";
import {AuthContext} from "../AuthContext";
import {UrlsEnum} from "../../enums/urls.enum";
import OperatorService from "../services/OperatorService";
import {setAuthToken} from "../services/http";



const getKey = async (tokenModel: TokenModel): Promise<TokenModel> => {
  const { data } = await OperatorService.getKey()
  tokenModel.operatorKey = data.operatorKey
  console.log(data.operatorKey)
  return tokenModel
}

const doLogin = async (userLogin: UserLogin): Promise<TokenModel> => {
  let { data } = await AuthService.login(userLogin)
  if (userLogin.userType === "company") {
    setAuthToken(data.accessToken, data.userType)
    data = await getKey(data)
  }
  return data
}


const useLogin = () => {

  const authContext = useContext(AuthContext)
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (userLogin: UserLogin) => doLogin(userLogin),
    onSuccess(token: TokenModel) {
      authContext.setAuthentication(token)
      navigate(UrlsEnum.INITIAL, {replace:true})
    }
  })
}
export default useLogin;

