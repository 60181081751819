import styled from "@emotion/styled";

const TextPoppins = styled.div<{$color?: string}>`
  color: ${props => props.$color || "#000"};
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;


export  const TextSmall = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-small);
`;

export const TextNormal = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-normal);
`;

export const TextMedium = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-medium);
`;

export const TextLarge = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-large);
`;


export const TextLargeStrong = styled(TextLarge)<{$color?: string}>`
  font-weight: 700;
`;

