import {createTheme} from "@mui/material/styles";

export const AppTheme = createTheme({
  palette: {
    primary: {
      main: "#2E62FE"

    },
    secondary: {
      main: "#F4F4F4"
    },

    error: {
      main: "#E69729"
    },
    /*,
    text: {
      primary: "#FFF",
      secondary: "#FFF",
    }*/
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    subtitle2: {
      color: "#F4F4F4"
    },
    //body1: {  // radio label, select items, link button
    //  color: "#2E62FE"
    //},
    body2: {
      color: "#001964"
    }
  },

});